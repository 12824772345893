import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "../../utils/apiUtils";

export const GET_PAYMENTS = "payment/GET_PAYMENTS";
export const GET_PAYMENT_DETAIL = "payment/GET_PAYMENT_DETAIL";

export const getPayments = createAsyncThunk(
    GET_PAYMENTS,
    async ({pageNum}) => {

        const data = {page: pageNum}

        return getApi('/api/payments', data);
    },
);

export const getPaymentDetail = createAsyncThunk(
    GET_PAYMENT_DETAIL,
    async ({id}) => {

        return getApi(`/api/payments/${id}`);
    },
);

const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        errorMessage: '',
        payments: [],
        maxPage: 0,
        detail: '',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getPayments.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                state.payments = [];
                state.maxPage = 0;
            }
            else {
                state.errorMessage = '';
                state.payments = action.payload.result;
                state.maxPage = action.payload.total_page;
            }
        })

        .addCase(getPaymentDetail.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.detail = action.payload;
            }
        })
    }
});

export default paymentSlice.reducer;