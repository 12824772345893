import { combineReducers, configureStore } from '@reduxjs/toolkit'
import home from '../home/modules/slice';
import login from '../login/modules/slice';
import registerUser from '../registerUser/modules/slice';
import resetPassword from '../resetPassword/modules/slice';
import locate from '../locate/modules/slice';
import notice from '../noticeBoard/modules/slice';
import question from '../questions/modules/slice';
import event from '../events/modules/slice';
import myPage from '../myPage/modules/slice';
import detergent from '../detergents/modules/slice';
import payment from '../purchaseHistory/modules/slice';
import modifyUserInfo from '../modifyUserInfo/modules/slice';
import coupon from '../coupons/modules/slice';
import review from '../reviews/modules/slice';
import letter from '../letters/modules/slice';
import ui from '../ui/modules/slice';


const store = configureStore({
  reducer: combineReducers({
    home,
    login,
    registerUser,
    resetPassword,
    locate,
    notice,
    question,
    event,
    myPage,
    detergent,
    payment,
    modifyUserInfo,
    coupon,
    review,
    letter,
    
    ui,
  }),
});

export default store;