import React from 'react';
import Layout from '../../layout/components/Layout';
import Letters from '../components/Letters';
import Title from '../../layout/components/Title';

const LetterListPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Title title="그린필레터">
                <Letters />
            </Title>
        </Layout>
    );
};

export default LetterListPage;