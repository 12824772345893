import React from 'react';
import Layout from '../../layout/components/Layout';
import ResetPassword from '../components/ResetPassword';
import VerifyPhoneForReset from '../components/VerifyPhoneForReset';
import { useSelector } from 'react-redux';
import ResetComplete from '../components/ResetComplete';

const ResetPasswordPage = () => {

    const {verifyState} = useSelector(state => state.resetPassword);

    return (
        <Layout hasHeader={true} hasFooter={true}>
            {verifyState === 'verified' ? <ResetPassword /> : verifyState === 'complete' ? <ResetComplete /> : <VerifyPhoneForReset />}
        </Layout>
    );
};

export default ResetPasswordPage;