import React from 'react';
import Layout from '../../layout/components/Layout';
import Question from '../components/Question';
import Title from '../../layout/components/Title';

const QuestionPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Title title="자주하는 질문">
                <Question />
            </Title>
        </Layout>
    );
};

export default QuestionPage;