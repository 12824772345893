import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "../../utils/apiUtils";

export const GET_TOP_NOTICE_LIST = "notice/GET_TOP_NOTICE_LIST";
export const GET_NOTICE_LIST = "notice/GET_NOTICE_LIST";
export const GET_NOTICE_DETAIL = "notice/GET_NOTICE_DETAIL";

export const getTopNoticeList = createAsyncThunk(
    GET_TOP_NOTICE_LIST,
    async () => {

        const data = {};
        
        return getApi('/api/announcements/top', data);
    },
);

export const getNoticeList = createAsyncThunk(
    GET_NOTICE_LIST,
    async ({pageNum}) => {

        const data = {page: pageNum};
        
        return getApi('/api/announcements', data);
    },
);

export const getNoticeDetail = createAsyncThunk(
    GET_NOTICE_DETAIL,
    async ({id}) => {

        return getApi(`/api/announcements/${id}`);
    },
);

const noticeSlice = createSlice({
    name: 'notice',
    initialState: {
        errorMessage: '',
        topList: [],
        list: [],
        maxPage: 0,
        noticeInitialPage: 1,
        detail: {},
    },
    reducers: {
        initNotice(state, action) {
            state.noticeInitialPage = 1;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getTopNoticeList.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.topList = action.payload;
            }
        })

        .addCase(getNoticeList.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                state.list = [];
                state.maxPage = 0;
                state.noticeInitialPage = 1;
            }
            else {
                state.errorMessage = '';
                state.list = action.payload.result;
                state.maxPage = action.payload.total_page;
                state.noticeInitialPage = action.meta.arg.pageNum;
            }
        })

        .addCase(getNoticeDetail.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.detail = action.payload;
            }
        })
    }
});

export const { initNotice } = noticeSlice.actions;
export default noticeSlice.reducer;