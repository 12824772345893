import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { closeDetailModal } from '../modules/slice';
import DetergentDetailItem from './DetergentDetailItem';
import { setFavoriteStore } from '../modules/slice';
import { getTimeString } from '../../utils/datetimeUtils';
import { openErrorModal, openLoginModal } from '../../ui/modules/slice';
import { getUserInfo } from '../../myPage/modules/slice';
import { HttpStatusCode } from 'axios';

const cx = classNames.bind(styles);

const DetailModal = () => {

    const accessToken = localStorage.getItem("accessToken");
    const { storeDetail, isOpenDetailModal } = useSelector(state => state.locate);
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(closeDetailModal());
    }

    const handleChangeFavorite = () => {
        if (!!accessToken) {
            dispatch(setFavoriteStore({id: storeDetail.id, favorite: !storeDetail.store_favorite, callback: (res) => {
                if (res.status === HttpStatusCode.Ok) {
                    dispatch(getUserInfo());
                }
                else {
                    dispatch(openErrorModal({message: res.data.message}));
                }
            }}));
        }
        else {
            dispatch(openLoginModal());
        }
    }

    return (
        isOpenDetailModal && <div className={cx("modal-background")}>
            <div className={cx("modal-background-color", isOpenDetailModal === true && "blocked")} onClick={() => handleCloseModal()} />
            <div className={cx("detail-modal-panel")}>
                <div className={cx("detail-modal-headline")}>
                    <div className={cx("detail-modal-header")}>
                        <div className={cx("detail-modal-subtitle")}>{storeDetail.title}</div>
                        <h3 className={cx("detail-modal-title")}>{storeDetail.title}</h3>
                    </div>
                    <button className={cx("detail-modal-close")} onClick={() => handleCloseModal()}>
                        <img src="icons/cross.svg"
                            alt="cross"
                            width={24}
                            height={24}
                        />
                    </button>
                </div>
                <div className={cx("detail-modal-scroll-view")}>
                    <dl>
                        <dt>주소</dt>
                        <dd>{storeDetail.address}</dd>
                        <dt>운영 시간</dt>
                        <dd>{getTimeString(storeDetail.open_time)} ~ {getTimeString(storeDetail.close_time)}</dd>
                        <dt>세제</dt>
                        <dd>
                            <div className={cx("detergent-detail-list-view")}>
                                <DetergentDetailItem imgSrc="icons/laundry-detergent.svg" itemName="세탁세제" list={storeDetail?.dispensers?.filter(dispenser => dispenser.product_type === "LAUNDRY_DETERGENT")} />
                                <DetergentDetailItem imgSrc="icons/fabric-softener.svg" itemName="섬유유연제" list={storeDetail?.dispensers?.filter(dispenser => dispenser.product_type === "FABRIC_SOFTENER")} />
                                <DetergentDetailItem imgSrc="icons/dish-detergent.svg" itemName="주방세제" list={storeDetail?.dispensers?.filter(dispenser => dispenser.product_type === "DISH_DETERGENT")} />
                            </div>
                        </dd>
                    </dl>
                </div>
                <button className={cx("list-item-full", storeDetail.store_favorite ? "outline-button" : "outline-gray-button", "modal-button")} onClick={() => handleChangeFavorite()}>
                    {storeDetail.store_favorite ? <div className={cx("button-content", "saved")}>
                        <img src="icons/favorite-on.svg" width={24} height={24}/>
                        <div>나의 그린필박스</div>
                    </div> : <div className={cx("button-content")}>
                        <img src="icons/favorite-off.svg" width={24} height={24}/>
                        <div>나의 그린필박스</div>
                    </div>}
                </button>
            </div>
        </div>
    );
}

export default DetailModal;