import React from 'react';
import Layout from '../../layout/components/Layout';
import HowToUse from '../components/HowToUse';

const HowToUsePage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true} pulldownTitle="그린필박스 이용방법">
            <HowToUse />
        </Layout>
    );
};

export default HowToUsePage;