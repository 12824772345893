import React from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { Link } from 'react-router-dom';
import { getDateString } from '../../utils/datetimeUtils';

const cx = classNames.bind(styles);

const NoticeListItem = (props) => {

    const { notice } = props;

    return (
        <Link to={`/notice/${notice.id}`} className={cx("notice-list-item")}>
            <div className={cx("notice-list-item-title")}>{notice.title}</div>
            <div className={cx("notice-list-item-date")}>{getDateString(notice.start_date)}</div>
        </Link>
    );
};

export default NoticeListItem;