import React, { useEffect } from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getCoupons } from '../modules/slice';
import Coupon from './Coupon';
import { handleImageError } from '../../utils/imageUtils';
import {ReactComponent as Empty} from '../../images/empty.svg';

const cx = classNames.bind(styles);

const CouponList = (props) => {

    const dispatch = useDispatch();
    const { coupons } = useSelector(state => state.coupon);
    const { filterState, setFilterState } = props;

    useEffect(() => {
        dispatch(getCoupons({type: filterState}));
    }, [filterState]);

    return (
        <div className={cx("coupon-list-view")}>
            <div className={cx("coupon-list-filter-view")}>
                <button className={cx(filterState === 'AVAILABLE' ? "active" : "deactive")} onClick={() => setFilterState('AVAILABLE')}>사용 가능 쿠폰</button>
                <button className={cx(filterState === 'UNAVAILABLE' ? "active" : "deactive")} onClick={() => setFilterState('UNAVAILABLE')}>지난 쿠폰</button>
            </div>
            {(!!coupons && coupons.length > 0) ?
                <ul className={cx("coupon-list")}>
                    {coupons.map(coupon => {
                        return <li className={cx("coupon-list-item")}><Coupon coupon={coupon}/></li>
                    })}
                </ul> : <div className={cx("coupon-list-empty")}>
                    <Empty className={cx("coupon-list-empty-image")}/>
                    <div className={cx("coupon-list-empty-text")}>아직 쿠폰이 없어요.</div>
                </div>
            }
        </div>
    );
};

export default CouponList;