import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../utils/apiUtils";

export const REGISTER_COUPON = "coupon/REGISTER_COUPON";
export const GET_COUPONS = "coupon/GET_COUPONS";

export const registerCoupon = createAsyncThunk(
    REGISTER_COUPON,
    async ({couponCode, callback}) => {

        const data = {coupon_code: couponCode}

        return postApi('/api/coupons/registration-coupon', data, null, callback);
    },
);

export const getCoupons = createAsyncThunk(
    GET_COUPONS,
    async ({type}) => {

        const data = {type};

        return getApi(`/api/coupons`, data);
    },
);

const couponSlice = createSlice({
    name: 'coupon',
    initialState: {
        errorMessage: '',
        coupons: [],
        //maxPage: 0,
        //detail: '',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(registerCoupon.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
            }
        })

        .addCase(getCoupons.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.coupons = action.payload;
            }
        })
    }
});

export default couponSlice.reducer;