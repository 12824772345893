import React from 'react';
import Layout from '../../layout/components/Layout';
import Login from '../components/Login';

const LoginPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Login />
        </Layout>
    );
};

export default LoginPage;