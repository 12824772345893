import React from 'react';
import Layout from '../../layout/components/Layout';
import ModifyUserInfo from '../components/ModifyUserInfo';
import Title from '../../layout/components/Title';


const ModifyUserInfoPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true} needToken={true}>
            <Title title={'회원 정보 수정'}>
                <ModifyUserInfo />
            </Title>
        </Layout>
    );
};

export default ModifyUserInfoPage;