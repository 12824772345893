import React from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const FilterItem = (props) => {

    const { imgSrc, filterName, isSelected, setSelected } = props;

    const toggleFilter = () => {
        setSelected(!isSelected);
    }

    return (
        <button className={cx("outline-gray-button", "filter-item", isSelected && "selected")} onClick={toggleFilter}>
            {imgSrc ? <img
                onError={handleImageError}
                src={imgSrc ?? defaultImage}
                alt={filterName}
                width={16}
                height={16}
            /> : ''}
            <span className={cx("filter-item-text")}>{filterName}</span>
        </button>
    );
};

export default FilterItem;