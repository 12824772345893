import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import ReviewDropdown from './ReviewDropdown';
import moment from 'moment';
import { toHtml } from '../../utils/stringUtils';

const cx = classNames.bind(styles);

const ReviewComponent = (props) => {

    const { review, setCurrPage } = props;
    const dropMenuRef = useRef(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handleOutsideClose = (e) => {
            // useRef current에 담긴 엘리먼트 바깥을 클릭 시 드롭메뉴 닫힘
            if(!dropMenuRef.current.contains(e.target)) setOpen(false);
        };
        if (open) {
            document.addEventListener('click', handleOutsideClose);
        }
        
        return () => document.removeEventListener('click', handleOutsideClose);
    }, [open]);

    return (
        <div className={cx("review-container")}>
            <div className={cx("review-header")}>
                <div className={cx("review-date")}>{!!review.created_at ? moment(review.created_at).format('YYYY-MM-DD HH:mm') : ''}</div>
                {review.is_current_user_review && <a className={cx("review-modify-field")} ref={dropMenuRef} href="#" onClick={() => setOpen(!open)}>
                    <div className={cx("review-modify-button")}>수정 및 삭제</div>
                    {open && <ReviewDropdown reviewId={review.id} setCurrPage={setCurrPage} />}
                </a>}
            </div>
            <div className={cx("review-body")}>
                <div className={cx("review-preview")}>
                    <div className={cx("review-user-info")}>
                        <h5 className={cx("review-user-name")}>{review.writer_name}</h5>
                        <div className={cx("review-location")}>{review.store_name}</div>
                    </div>
                    <div className={cx("review-content")} dangerouslySetInnerHTML={{__html: toHtml(review.content)}} />
                </div>
                {!!review.review_image_url && <img src={review.review_image_url} width={160} height={160}/>}
            </div>
        </div>
    );
};

export default ReviewComponent;