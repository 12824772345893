import React, { useState } from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch } from 'react-redux';
import { getCoupons, registerCoupon } from '../modules/slice';
import { HttpStatusCode } from 'axios';

const cx = classNames.bind(styles);

const RegisterCoupon = (props) => {

    const dispatch = useDispatch();
    const { setFilterState } = props;
    const [couponCode, setCouponCode] = useState('');

    const handleChangeCouponCode = e => {
        setCouponCode(e.target.value);
    }

    const handleSubmit = () => {
        dispatch(registerCoupon({couponCode, callback: (res) => {
            if (res.status === HttpStatusCode.Ok) {
                setFilterState('AVAILABLE');
                dispatch(getCoupons({type: 'AVAILABLE'}));
            }
            else {
                alert(res.data.message);
            }
        }}));
    }

    return (
        <div className={cx("register-coupon-view")}>
            <input className={cx("register-coupon-input")}
                name="couponCode"
                value={couponCode}
                placeholder='쿠폰 코드를 입력해주세요.'
                onChange={handleChangeCouponCode}
            />
            <button className={cx("register-coupon-button", "outline-gray-button")} onClick={handleSubmit}>
                쿠폰 등록
            </button>
        </div>
    );
};

export default RegisterCoupon;