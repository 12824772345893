import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import '../styles/Slider.scss';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { urlCheck } from '../../utils/stringUtils';
import { defaultImage, handleImageError } from '../../utils/imageUtils';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH } from '../../utils/constants';

const cx = classNames.bind(styles);

const MainBanner = (props) => {

    const { banners } = props;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

    return (
        <div className={cx("main-banner")}>
            {!!banners ? <Swiper
                spaceBetween={0}
                centeredSlides={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                speed={3000}
                pagination={banners.length > 1}
                navigation={!isMobile && banners.length > 1}
                modules={!isMobile ? [Autoplay, Pagination, Navigation] : [Autoplay, Pagination]}
                loop={true}
                onSlideChangeTransitionStart={e => {
                }}
            >
              {banners.map((banner, idx) => {
                return <SwiperSlide key={idx}>
                  <a href={urlCheck(banner.action_url)} target="_blank">
                    <img onError={handleImageError} className={cx("banner-image")} src={(!isMobile ? banner.pc_image_url : banner.mobil_image_url) ?? defaultImage} alt={banner.title} />
                  </a>
                </SwiperSlide>;
              })}
            </Swiper> : ''}
        </div>
    );
};

export default MainBanner;