
export const toHtml = (str) => {
    return !!str ? str.replaceAll('\r\n', '<br/>').replaceAll('\n', '<br/>') : '';
}

export const renderPrice = (value) => {
    return !!value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0';
}

export const urlCheck = (url) => {
    if (!!url) {
        if (url.includes('://')) {
            return url;
        } else {
            return 'http://' + url;
        }
    }
    else {
        return '';
    }
}