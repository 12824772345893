import React, { useRef, useState } from 'react';
import { upload } from '../../utils/imageUtils';

const UploadPage = () => {
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const fileElement = useRef(null);

    const onUploaded = (url) => {
        alert(url);
    }

    const selectImage = () => {
        if (!loading) {
            fileElement.current.click();
        }
    };

    const uploadImage = (e) => {
        if (fileElement.current.files.length === 1) {
            try {
                e.persist();
                setLoading(true);
                upload(
                    fileElement.current.files[0],
                    (percent) => {
                    setUploadProgress(percent);
                    },
                    (result) => {
                    setLoading(false);
                    setUploadProgress(0);
                    onUploaded(`https://grfimgbucket.s3.ap-northeast-2.amazonaws.com/${result.Key}`);
                    },
                );
            } catch (err) {
                if (err) {
                    alert(err.message);
                    throw new Error(err);
                }
            }
        } else {
            alert('파일을 선택해주세요');
        }
    };

    return (
        <>
            <input type="file"
                onChange={uploadImage}
                ref={fileElement}
                accept='image/*'
                multiple={false}
                style={{ display: 'none' }}
            />
            <button onClick={selectImage}>업로드</button>
        </>
    );
};

export default UploadPage;