import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../modules/slice';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';

const cx = classNames.bind(styles);

const ResetPassword = () => {

    const loginAccessToken = localStorage.getItem('accessToken');
    const { accessToken, errorMessage } = useSelector(state => state.resetPassword);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCheck, setShowPasswordCheck] = useState(false);

    const [errors, setErrors] = useState({
        password: '',
        passwordCheck: '',
    });

    useEffect(() => {
        if (!!loginAccessToken) {
            navigate('/', {replace: true});
        }
    }, [navigate, loginAccessToken]);

    useEffect(() => {
        const errors = {
            password: errorMessage,
            passwordCheck: '',
        };
        setErrors(errors);
    }, [errorMessage]);

    const handleChangePassword = e => {
        setPassword(e.target.value);
    }

    const handleChangePasswordCheck = e => {
        setPasswordCheck(e.target.value);
    }

    const validate = () => {
        const errors = {
            password: "",
            passwordCheck: "",
        };

        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,16}$/;
    
        if (!password || !passwordRegex.test(password)) {
            errors.password = "비밀번호를 다시 확인해주세요.";
        }

        if (!passwordCheck || password !== passwordCheck) {
            errors.passwordCheck = "비밀번호가 일치하지 않습니다.";
        }
    
        return errors;
    }

    const handleSubmit = e => {
        e.preventDefault()

        const errors = validate();
        // 에러 값을 설정하고
        setErrors(errors);
        // 잘못된 값이면 제출 처리를 중단한다.
        if (Object.values(errors).some(v => v)) {
          return;
        }

        dispatch(resetPassword({accessToken: accessToken, password: password}));
    }

    return (
        <div className={cx("reset-password-view")}>
            <h1 className={cx("reset-password-title")}>비밀번호 재설정</h1>
            <div className={cx("list-item-full")}>
                <form onSubmit={handleSubmit}>
                    <p className={cx("reset-password-desc")}>새로운 비밀번호를 입력하세요.</p>
                    <div className={cx("list-item-full", "password-input-field")}>
                        <input className={cx("list-item-full", "password-input")}
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            placeholder='영문, 숫자, 특수문자 포함 8자리 이상'
                            onChange={handleChangePassword}
                        />
                        <img
                            className={cx("toggle-show-password-button")}
                            title={showPassword ? "숨기기" : "보이기"}
                            alt="visibility"
                            src={showPassword ? 'icons/visibility-off.svg' : 'icons/visibility-on.svg'}
                            onClick={() => setShowPassword(show => !show)}
                        />
                        {errors.password && <div className={cx("input-error-text")}><span>{errors.password}</span></div>}
                    </div>
                    <div className={cx("list-item-full", "password-input-field")}>
                        <input className={cx("list-item-full", "password-input")}
                            type={showPasswordCheck ? "text" : "password"}
                            name="passwordCheck"
                            value={passwordCheck}
                            placeholder='비밀번호 확인'
                            onChange={handleChangePasswordCheck}
                        />
                        <img
                            className={cx("toggle-show-password-button")}
                            title={showPasswordCheck ? "숨기기" : "보이기"}
                            alt="visibility"
                            src={showPasswordCheck ? 'icons/visibility-off.svg' : 'icons/visibility-on.svg'}
                            onClick={() => setShowPasswordCheck(show => !show)}
                        />
                        {errors.passwordCheck && <div className={cx("input-error-text")}><span>{errors.passwordCheck}</span></div>}
                    </div>
                    <div className={cx("list-item-full")}>
                        <button className={cx("list-item-full", "primary-button", "confirm-button")} type="submit">비밀번호 변경하기</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;