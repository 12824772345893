import React, { useEffect } from 'react';
import Header from "./Header";
import Footer from "./Footer";
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

const Layout = (props) => {

    const navigate = useNavigate();
    const {hasHeader, hasFooter, pulldownTitle, needToken = false} = props;

    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        if (needToken && !accessToken) {
            navigate('/', {replace: true});
        }
    }, [needToken, accessToken]);

    return (
        <>
            {hasHeader && <Header pulldownTitle={pulldownTitle} />}
            <main className={cx('main', hasHeader ? 'has-header' : '', hasFooter ? 'has-footer' : '')}>
                {props.children}
                {hasFooter && <Footer />}
            </main>
        </>
    );
}

export default Layout;