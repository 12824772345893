import React, { Fragment, useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getDateString } from '../../utils/datetimeUtils';
import { renderPrice } from '../../utils/stringUtils';
import { getPayments } from '../modules/slice';
import PageNumbers from '../../ui/components/PageNumbers';
import { useNavigate } from 'react-router-dom';
import { deleteReview, initReviews } from '../../reviews/modules/slice';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const MobilePurchaseHistory = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { payments, maxPage } = useSelector(state => state.payment);
    const { updated } = useSelector(state => state.review);
    const [currPage, setCurrPage] = useState(1);

    useEffect(() => {
        dispatch(getPayments({pageNum: currPage}));
    }, [dispatch]);

    useEffect(() => {
        if (updated) {
            dispatch(getPayments({pageNum: currPage}));
            dispatch(initReviews());
        }
    }, [updated]);

    const handleChangePage = (page) => {
        setCurrPage(page);
        dispatch(getPayments({pageNum: page}));
    }

    const handleClickRow = (id) => {
        navigate(`/payment/${id}`);
    }

    const handleWriteReview = (id) => {
        navigate(`/review/write/${id}`);
    }

    const handleUpdateReview = (reviewId) => {
        navigate(`/review/modify/${reviewId}`);
    }

    const handleDeleteReview = (reviewId) => {
        dispatch(deleteReview({reviewId}));
    }

    return (
        <ul className={cx("purchase-history-list")}>
            {!!payments ? payments.map((payment) => {
                return <li className={cx("purchase-history-block")} onClick={() => handleClickRow(payment.id)}>
                    <div className={cx("purchase-info-cell")}>
                        <div className={cx("purchase-date")}>
                            <span className={cx("purchase-date-text")}>{getDateString(payment.created_at)}</span>
                            <span className={cx("purchase-spot")}>{payment.store.title}</span>
                        </div>
                        {!payment.reviewable ? <Fragment>
                            <div className={cx("button-group")}>
                                <button className={cx("outline-gray-button", "table-button")} onClick={() => handleUpdateReview(payment.review_id)}>후기 수정</button>
                                <button className={cx("outline-gray-button", "table-button")} onClick={() => handleDeleteReview(payment.review_id)}>후기 삭제</button>
                            </div>
                        </Fragment> : <button className={cx("outline-button", "table-button")} onClick={() => handleWriteReview(payment.id)}>
                            후기 작성
                        </button>}
                    </div>
                    {!!payment?.payment_items ? payment?.payment_items.map((item) => {
                        return <div className={cx("purchase-item-cell")}>
                            <img onError={handleImageError} src={item?.product.thumb_image_url ?? defaultImage} width={64} height={64}/>
                            <div className={cx("purchase-item-header")}>
                                <div className={cx("purchase-item-title-line")}><span className={cx("purchase-item-title")}>{item.product.brand_name}</span><span className={cx("purchase-item-subtitle")}>{item.product.title}</span></div>
                                <div className={cx("purchase-item-desc")}>{renderPrice(item.product.item_capacity)}ml</div>
                                <div className={cx("purchase-price")}>{renderPrice(item.product.price)}원</div>
                            </div>
                        </div>;
                    }) : ''}
                </li>;
            }) : ''}
            <PageNumbers currPage={currPage} maxPage={maxPage} onChangePage={handleChangePage} />
        </ul>
    );
};

export default MobilePurchaseHistory;