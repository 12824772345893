import React, { useState } from 'react';
import DropdownFilter from './DropdownFilter';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';

const cx = classNames.bind(styles);

const MultiFilterItem = (props) => {

    const { filterName, filters, setFilters } = props;
    const [open, setOpen] = useState(false);

    return (
        <button className={cx("outline-gray-button", "filter-item", "multi-filter-item", !!filters ? Object.values(filters).some(f => !f.value) ? "selected" : "" : "")} onClick={() => setOpen(true)}>
            <span className={cx("multi-filter-item-text")}>{filterName}</span>
            <img
                src="icons/pull-down.svg"
                alt="filterName"
                width={24}
                height={24}
            />
            {open && <DropdownFilter isOpen={open} setOpen={setOpen} filters={filters} setFilters={setFilters} />}
        </button>
    );
};

export default MultiFilterItem;