import React, { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {ReactComponent as RightArrow} from '../../icons/arrow-right-small.svg';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getDateString } from '../../utils/datetimeUtils';
import { renderPrice } from '../../utils/stringUtils';
import { deleteReview } from '../../reviews/modules/slice';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const MobilePurchaseHistory = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { myInfo } = useSelector(state => state.myPage);

    const handleWriteReview = (id) => {
        navigate(`/review/write/${id}`);
    }

    const handleUpdateReview = (reviewId) => {
        navigate(`/review/modify/${reviewId}`);
    }

    const handleDeleteReview = (reviewId) => {
        dispatch(deleteReview({reviewId}));
    }

    return (
        <div className={cx("my-page-purchase-history")}>
            <div className={cx("my-page-purchase-history-header")}>
                <h3 className={cx("my-page-subtitle")}>구매내역</h3>
                <Link className={cx("purchase-history-button")} to="/payment">
                    <span>전체 보기</span>
                    <RightArrow className={cx("fill-gray-800-color")} width={16} height={16} />
                </Link>
            </div>
            <ul className={cx("purchase-history-list")}>
                {!!myInfo?.payments ? myInfo?.payments.map((payment) => {
                    return <div className={cx("purchase-history-block")}>
                        <div className={cx("purchase-info-cell")}>
                            <div className={cx("purchase-date")}>
                                <span className={cx("purchase-date-text")}>{getDateString(payment.created_at)}</span>
                                <span className={cx("purchase-spot")}>{payment.store.title}</span>
                            </div>
                            {!payment.reviewable ? <Fragment>
                                <div className={cx("button-group")}>
                                    <button className={cx("outline-gray-button", "table-button")} onClick={() => handleUpdateReview(payment.review_id)}>후기 수정</button>
                                    <button className={cx("outline-gray-button", "table-button")} onClick={() => handleDeleteReview(payment.review_id)}>후기 삭제</button>
                                </div>
                            </Fragment> : <button className={cx("outline-button", "table-button")} onClick={() => handleWriteReview(payment.id)}>
                                후기 작성
                            </button>}
                        </div>
                        {!!payment?.payment_items ? payment?.payment_items.map((item) => {
                            return <div className={cx("purchase-item-cell")}>
                                <img onError={handleImageError} src={item?.product.thumb_image_url ?? defaultImage} width={64} height={64}/>
                                <div className={cx("purchase-item-header")}>
                                    <div className={cx("purchase-item-title-line")}><span className={cx("purchase-item-title")}>{item.product.brand_name}</span><span className={cx("purchase-item-subtitle")}>{item.product.title}</span></div>
                                    <div className={cx("purchase-item-desc")}>{renderPrice(item.product.item_capacity)}ml</div>
                                    <div className={cx("purchase-price")}>{renderPrice(item.product.price)}원</div>
                                </div>
                            </div>;
                        }) : ''}
                    </div>;
                }) : ''}
            </ul>
        </div>
    );
};

export default MobilePurchaseHistory;