import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import {ReactComponent as PasswordChange} from '../../images/password-change.svg';

const cx = classNames.bind(styles);

const ResetComplete = () => {

    return (
        <div className={cx("reset-password-view")}>
            <PasswordChange className={cx("complete-image")} />
            <h3 className={cx("complete-message")}>비밀번호 변경이 완료되었어요!</h3>
            <Link to="/login" className={cx("list-item-full")}>
                <button className={cx("list-item-full", "primary-button", "confirm-button")}>로그인</button>
            </Link>
        </div>
    );
};

export default ResetComplete;