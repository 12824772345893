import React from 'react';
import Layout from '../../layout/components/Layout';
import Title from '../../layout/components/Title';
import ModifyReview from '../components/ModifyReview';

const ModifyReviewPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Title title='후기 수정'>
                <ModifyReview />
            </Title>
        </Layout>
    );
};

export default ModifyReviewPage;