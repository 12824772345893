import React, { useEffect } from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getEventDetail } from '../modules/slice';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { toHtml, urlCheck } from '../../utils/stringUtils';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const EventDetail = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { detail } = useSelector(state => state.event);

    const curr = moment();
    const isActive = moment(detail.start_date, 'YYYY-MM-DD').isBefore(curr) && moment(detail.end_date, 'YYYY-MM-DD').isAfter(curr);

    const { id } = useParams();

    useEffect(() => {
        dispatch(getEventDetail({id}));
    }, [dispatch]);


    const handleBack = () => {
        navigate("/event");
    }

    return (
        <div className={cx("event-detail-view")}>
            <h2 className={cx("event-detail-title")}>{detail.title}</h2>
            <div className={cx("event-detail-subheader")}>
                {isActive ? <div className={cx("event-list-item-progress", "processing")}>진행중</div> : <div className={cx("event-list-item-progress", "finished")}>이벤트 종료</div>}
                <div className={cx("event-detail-create-date")}>{moment(detail.start_date, 'YYYY-MM-DD').format('YYYY.MM.DD')}~{moment(detail.end_date, 'YYYY-MM-DD').format('YYYY.MM.DD')}</div>
            </div>
            <div className={cx("event-detail-content")}>
                <img className={cx("event-detail-image")} onError={handleImageError} src={detail.main_image_url ?? defaultImage} alt={detail.title} />
                <div className={cx("event-detail-desc")} dangerouslySetInnerHTML={{__html: toHtml(detail.description)}} />
                {!!detail.action_url && <a href={urlCheck(detail.action_url)} target="_blank">
                    <button className={cx("primary-button", "event-detail-link-button")}>
                        이벤트 참여
                    </button>
                </a>}
            </div>
            <div className={cx("event-detail-buttons")}>
                <button className={cx("outline-gray-button", "event-detail-button")} onClick={() => handleBack()}>목록으로</button>
            </div>
        </div>
    );
};

export default EventDetail;