import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';

const cx = classNames.bind(styles);

const DropdownMenu = () => {

    const location = useLocation();

    const checkRefresh = (url) => {
        if (location.pathname === url) {
            window.location.reload();
        }
    }

    return (
        <ul className={cx("dropdown-list")}>
            <Link className={cx("dropdown-text")} to="/introduction" onClick={() => checkRefresh("/introduction")}>
                <li className={cx("dropdown-menu")}>그린필박스 소개</li>
            </Link>
            <Link className={cx("dropdown-text")} to="/how-to-use" onClick={() => checkRefresh("/how-to-use")}>
                <li className={cx("dropdown-menu")}>그린필박스 이용방법</li>
            </Link>
            <Link className={cx("dropdown-text")} to="/locate" onClick={() => checkRefresh("/locate")}>
                <li className={cx("dropdown-menu")}>그린필박스 위치</li>
            </Link>
            <Link className={cx("dropdown-text")} to="/detergents" onClick={() => checkRefresh("/detergents")}>
                <li className={cx("dropdown-menu")}>그린필박스 세제 정보</li>
            </Link>
        </ul>
    );
}

export default DropdownMenu;