import axios from "axios";

export const getHeader = () => {
    return {
        "Accept": "application/json; charset=utf-8",
        "X-User-Token": localStorage.getItem("accessToken"),
    };
}

export const getApi = (url, data, customHeader, callback) => {
    let header = getHeader();
    if (!!customHeader) header = customHeader;
    return axios.get(process.env.REACT_APP_API_URL + url, {
        params: data,
        headers: header,
    })
    .then((res) => {
        !!callback && callback(res);
        return res.data;
    })
    .catch((err) => {
        !!callback && callback(err.response);
        return {error: err.response.data ?? err};
    });
}

export const postApi = (url, data, customHeader, callback) => {
    let header = getHeader();
    if (!!customHeader) header = customHeader;
    return axios.post(process.env.REACT_APP_API_URL + url, !!data ? data : '', {
        headers: header
    })
    .then((res) => {
        !!callback && callback(res);
        return res.data;
    })
    .catch((err) => {
        !!callback && callback(err.response);
        return {error: err.response.data ?? err};
    });
}

export const putApi = (url, data, customHeader, callback) => {
    let header = getHeader();
    if (!!customHeader) header = customHeader;
    return axios.put(process.env.REACT_APP_API_URL + url, !!data ? data : '', {
        headers: header
    })
    .then((res) => {
        !!callback && callback(res);
        return res.data;
    })
    .catch((err) => {
        !!callback && callback(err.response);
        return {error: err.response.data ?? err};
    });
}

export const deleteApi = (url, data, customHeader, callback) => {
    let header = getHeader();
    if (!!customHeader) header = customHeader;
    return axios.delete(process.env.REACT_APP_API_URL + url, {
        params: data,
        headers: header,
    })
    .then((res) => {
        !!callback && callback(res);
        return res.data;
    })
    .catch((err) => {
        !!callback && callback(err.response);
        return {error: err.response.data ?? err};
    });
}