import React from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { getDDay, getDateString } from '../../utils/datetimeUtils';
import { renderPrice } from '../../utils/stringUtils';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const Coupon = (props) => {

    const { coupon } = props;

    const isActive = coupon.status === 'AVAILABLE' ? true : false;

    return (
        <div className={cx("coupon-view", !isActive ? "deactive" : "")}>
            <div className={cx("coupon-title", !isActive ? "deactive" : "")}>{coupon.coupon_policy.title}</div>
            <div className={cx("coupon-header")}>
                <div className={cx("coupon-subtitle", !isActive ? "deactive" : "")}>{renderPrice(coupon.coupon_policy.discount_value)}{coupon.coupon_policy.discount_method === 'PRICE' ? '원' : '%'} 할인</div>
                {!isActive && <div className={cx("coupon-status")}>{coupon.status === 'USED' ? '사용 완료' : '기간 만료'}</div>}
            </div>
            <img className={cx("coupon-qr-code")} onError={handleImageError} src={coupon.qr_code_url ?? defaultImage} />
            <div className={cx("coupon-desc")}>{coupon.coupon_policy.description}</div>
            <div className={cx("coupon-date")}>{getDateString(coupon.start_date)} ~ {getDateString(coupon.end_date)}{isActive && <span className={cx("coupon-remain-date")}>{getDDay(coupon.end_date)}일 남음</span>}</div>
        </div>
    );
};

export default Coupon;