import React from 'react';
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { Link } from 'react-router-dom';
import StampBar from './StampBar';
import {ReactComponent as RightArrow} from '../../icons/arrow-right-small.svg';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);

const CouponInfo = () => {

    const { myInfo } = useSelector(state => state.myPage);

    return (
        <div className={cx("my-page-coupon-stamp-view")}>
            <div className={cx("my-page-coupon-stamp-view-header")}>
                <div className={cx("my-page-coupon-stamp-view-title")}>
                    {myInfo.name}님,<br/>
                    지구를 채우는 {myInfo.total_payment_item_count}번째 실천 중
                </div>
                <Link className={cx("my-page-modify-user-button")} to="/user/modify">
                    <span>회원정보 수정</span>
                    <RightArrow className={cx("fill-gray-300-color")} width={16} height={16} />
                </Link>
            </div>
            <div className={cx("my-page-coupon-stamp-body")}>
                <StampBar stampCount={myInfo.stamp_count} />
                <div className={cx("coupon-summary-view")}>
                    <div className={cx("coupon-summary-body")}>{myInfo.available_coupon_count}개</div>
                    <div className={cx("coupon-summary-header")}>
                        <div className={cx("coupon-summary-title")}>쿠폰</div>
                        <Link to="/coupon" className={cx("coupon-summary-detail-button")}>
                            <div>쿠폰 리스트</div>
                            <RightArrow stroke={`var(--gray-800-color)`} width={16} height={16} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CouponInfo;