import React from 'react';
import Layout from '../../layout/components/Layout';
import LetterDetail from '../components/LetterDetail';

const LetterPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <LetterDetail />
        </Layout>
    );
};

export default LetterPage;