import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../modules/slice';
import 'react-datepicker/dist/react-datepicker.css';
import {ReactComponent as Visible} from '../../icons/visibility-on.svg';
import {ReactComponent as Unvisible} from '../../icons/visibility-off.svg';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import {ReactComponent as RightArrow} from '../../icons/arrow-right.svg';
import { checkBirthDate } from '../../utils/datetimeUtils';

const cx = classNames.bind(styles);

const RegisterUser = () => {

    const { phoneNumber } = useSelector(state => state.registerUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = localStorage.getItem('accessToken');

    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [userName, setUserName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [allAgreement, setAllAgreement] = useState(false);
    const [usageAgreement, setUsageAgreement] = useState(false);
    const [emailAgreement, setEmailAgreement] = useState(false);
    const [smsAgreement, setSmsAgreement] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCheck, setShowPasswordCheck] = useState(false);


    const [errors, setErrors] = useState({
        phoneNumber: "",
        password: "",
        passwordCheck: "",
        userName: "",
        usageAgreement: "",
        birthDate: "",
    });

    useEffect(() => {
        if (!!accessToken) {
            navigate('/', {replace: true});
        }
    }, [navigate, accessToken]);

    const handleChangePassword = e => {
        setPassword(e.target.value);
    }

    const handleChangePasswordCheck = e => {
        setPasswordCheck(e.target.value);
    }

    const handleChangeUserName = e => {
        setUserName(e.target.value);
    }

    const handleChangeEmailAddress = e => {
        setEmailAddress(e.target.value);
    }

    const handleChangeBirthDate = e => {
        const date = e.target.value;

        setBirthDate(date
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{4})(\d{1,2})(\d{1,2})$/, `$1-$2-$3`));
    }

    const handleChangeAllAgreement = check => {
        setAllAgreement(check);
        setUsageAgreement(check);
        setEmailAgreement(check);
        setSmsAgreement(check);
    }

    const handleChangeUsageAgreement = check => {
        setUsageAgreement(check);
        if (check && emailAgreement && smsAgreement) {
            setAllAgreement(check);
        }
        else if (!check && allAgreement) {
            setAllAgreement(check);
        }
    }

    const handleChangeEmailAgreement = check => {
        setEmailAgreement(check);
        if (check && usageAgreement && smsAgreement) {
            setAllAgreement(check);
        }
        else if (!check && allAgreement) {
            setAllAgreement(check);
        }
    }

    const handleChangeSmsAgreement = check => {
        setSmsAgreement(check);
        if (check && usageAgreement && emailAgreement) {
            setAllAgreement(check);
        }
        else if (!check && allAgreement) {
            setAllAgreement(check);
        }
    }

    const validate = () => {
        const errors = {
            password: "",
            passwordCheck: "",
            userName: "",
            emailAddress: "",
            usageAgreement: "",
            birthDate: "",
        };

        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,16}$/;
        const emailRegex = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;
    
        if (!password || !passwordRegex.test(password)) {
            errors.password = "비밀번호를 다시 확인해주세요.";
        }
        if (!passwordCheck || password !== passwordCheck) {
            errors.passwordCheck = "비밀번호가 일치하지 않습니다.";
        }

        if (!userName) {
            errors.userName = "필수 입력 항목입니다.";
        }

        if (!!emailAddress && !emailRegex.test(emailAddress)) {
            errors.emailAddress = "이메일 형식이 올바르지 않습니다.";
        }

        if (!!birthDate && !checkBirthDate(birthDate)) {
            errors.birthDate = "생년월일이 올바르지 않습니다.";
        }

        if (!usageAgreement) {
            errors.usageAgreement = "필수 입력 항목입니다.";
            alert('개인정보 수집 동의는 필수입니다.');
        }
    
        return errors;
    }

    const handleSubmit = e => {
        e.preventDefault()

        const errors = validate();
        // 에러 값을 설정하고
        setErrors(errors);
        // 잘못된 값이면 제출 처리를 중단한다.
        if (Object.values(errors).some(v => v)) {
          return;
        }

        dispatch(registerUser({phone: phoneNumber.replace(/[^0-9]/g, ''), password: password, name: userName, email: emailAddress, birthDate, emailAgreement, smsAgreement}));
    }

    return (
        <div className={cx("register-view")}>
            <h1 className={cx("register-title")}>회원가입</h1>
            <div className={cx("list-item-full")}>
                <form className={cx("register-form-field")} onSubmit={handleSubmit}>
                    <div className={cx("list-item-full")}>
                        <div className={cx("input-title")}>
                            <span>휴대폰 번호 </span>
                            <span className={cx("input-required")}>*</span>
                        </div>
                        <input className={cx("list-item-full")}
                            type="text"
                            name="phoneNumber"
                            value={phoneNumber}
                            placeholder='휴대폰 번호(-없이 숫자만 입력)'
                            disabled={true}
                        />
                        {errors.phoneNumber && <div className={cx("input-error-text")}><span>{errors.phoneNumber}</span></div>}
                    </div>
                    <div className={cx("list-item-full")}>
                        <div className={cx("input-title")}>
                            <span>비밀번호 </span>
                            <span className={cx("input-required")}>*</span>
                        </div>
                        <div className={cx("list-item-full", "password-input-field")}>
                            <input className={cx("list-item-full", "password-input")}
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={password}
                                placeholder='영문, 숫자, 특수문자 포함 8자리 이상'
                                onChange={handleChangePassword}
                            />
                            {showPassword ? <Visible
                                className={cx("toggle-show-password-button")}
                                onClick={() => setShowPassword(show => !show)}
                            /> : <Unvisible
                                className={cx("toggle-show-password-button")}
                                onClick={() => setShowPassword(show => !show)}
                            />}
                            {errors.password && <div className={cx("input-error-text")}><span>{errors.password}</span></div>}
                        </div>
                    </div>
                    <div className={cx("list-item-full")}>
                        <div className={cx("input-title")}>
                            <span>비밀번호 확인 </span>
                            <span className={cx("input-required")}>*</span>
                        </div>
                        <div className={cx("list-item-full", "password-input-field")}>
                            <input className={cx("list-item-full", "password-input")}
                                type={showPasswordCheck ? "text" : "password"}
                                name="passwordCheck"
                                value={passwordCheck}
                                placeholder='비밀번호 확인'
                                onChange={handleChangePasswordCheck}
                            />
                            {showPasswordCheck ? <Visible
                                className={cx("toggle-show-password-button")}
                                onClick={() => setShowPasswordCheck(show => !show)}
                            /> : <Unvisible
                                className={cx("toggle-show-password-button")}
                                onClick={() => setShowPasswordCheck(show => !show)}
                            />}
                            {errors.passwordCheck && <div className={cx("input-error-text")}><span>{errors.passwordCheck}</span></div>}
                        </div>
                    </div>
                    <div className={cx("list-item-full")}>
                        <div className={cx("input-title")}>
                            <span>이름 </span>
                            <span className={cx("input-required")}>*</span>
                        </div>
                        <input className={cx("list-item-full")}
                            name="userName"
                            value={userName}
                            placeholder='김그린'
                            onChange={handleChangeUserName}
                        />
                        {errors.userName && <div className={cx("input-error-text")}><span>{errors.userName}</span></div>}
                    </div>
                    <div className={cx("list-item-full")}>
                        <div className={cx("input-title")}>
                            <span>이메일 </span>
                        </div>
                        <input className={cx("list-item-full")}
                            name="emailAddress"
                            value={emailAddress}
                            placeholder='greenfill@email.com'
                            onChange={handleChangeEmailAddress}
                        />
                        {errors.emailAddress && <div className={cx("input-error-text")}><span>{errors.emailAddress}</span></div>}
                    </div>
                    <div className={cx("list-item-full")}>
                        <div className={cx("input-title")}>
                            <span>생년월일 </span>
                        </div>
                        <input className={cx("list-item-full")}
                            name="birthDate"
                            value={birthDate}
                            placeholder='생년월일을 숫자만 입력해주세요.'
                            onChange={handleChangeBirthDate}
                            maxLength={10}
                        />
                        {errors.birthDate && <div className={cx("input-error-text")}><span>{errors.birthDate}</span></div>}
                        <div className={cx("input-tooltip-text")}>
                            <span>생년월일을 입력하면 <span className={cx("coupon-text")}>생일 쿠폰</span>을 드려요!</span>
                        </div>
                    </div>
                    <div className={cx("list-item-full", "checkbox-area")}>
                        <div className={cx("list-item-full", "register-checkbox-input-field")}>
                            <div className={cx("checkbox-input-field")}>
                                <input id="allAgreement"
                                    className={cx("checkbox")}
                                    type="checkbox"
                                    checked={allAgreement}
                                    onChange={({ target: { checked } }) => handleChangeAllAgreement(checked)}
                                />
                                <label for="allAgreement" className={cx("checkbox-label")}>
                                    <span>전체 동의</span>
                                </label>
                            </div>
                        </div>
                        <div className={cx("list-item-full", "register-checkbox-input-field")}>
                            <div className={cx("checkbox-input-field")}>
                                <input id="usageAgreement"
                                    className={cx("checkbox")}
                                    name="usageAgreement"
                                    type="checkbox"
                                    checked={usageAgreement}
                                    onChange={({ target: { checked } }) => handleChangeUsageAgreement(checked)}
                                />
                                <label for="usageAgreement" className={cx("checkbox-label")}>
                                    <span>[필수]개인정보 수집 동의</span>
                                </label>
                            </div>
                            <a target="_blank" href="https://cypress-curiosity-65a.notion.site/Ver-1-f43ee3eecad94f429482b7339cbbfe89?pvs=4">
                                <RightArrow className={cx("arrow-right")} />
                            </a>
                        </div>
                        <div className={cx("list-item-full", "register-checkbox-input-field")}>
                            <div className={cx("checkbox-input-field")}>
                                <input id="emailAgreement"
                                    className={cx("checkbox")}
                                    type="checkbox"
                                    checked={emailAgreement}
                                    onChange={({ target: { checked } }) => handleChangeEmailAgreement(checked)}
                                />
                                <label for="emailAgreement" className={cx("checkbox-label")}>
                                    <span>[선택]이메일 수신</span>
                                </label>
                            </div>
                            <a target="_blank" href="https://cypress-curiosity-65a.notion.site/a20ec15b7f864bf1a9f886e259ebea76?pvs=4">
                                <RightArrow className={cx("arrow-right")} />
                            </a>
                        </div>
                        <div className={cx("list-item-full", "register-checkbox-input-field")}>
                            <div className={cx("checkbox-input-field")}>
                                <input id="smsAgreement"
                                    className={cx("checkbox")}
                                    type="checkbox"
                                    checked={smsAgreement}
                                    onChange={({ target: { checked } }) => handleChangeSmsAgreement(checked)}
                                />
                                <label for="smsAgreement" className={cx("checkbox-label")}>
                                    <span>[선택]SMS 수신</span>
                                </label>
                            </div>
                            <a target="_blank" href="https://cypress-curiosity-65a.notion.site/a20ec15b7f864bf1a9f886e259ebea76?pvs=4">
                                <RightArrow className={cx("arrow-right")} />
                            </a>
                        </div>
                    </div>
                    <div className={cx("list-item-full", "confirm-button-field")}>
                        <button className={cx("list-item-full", "primary-button", "confirm-button")} type="submit">회원가입</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RegisterUser;