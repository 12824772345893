import React, { useEffect, useState } from 'react';
import {ReactComponent as Cross} from '../../icons/cross.svg';
import {ReactComponent as Visible} from '../../icons/visibility-on.svg';
import {ReactComponent as Unvisible} from '../../icons/visibility-off.svg';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { changePassword } from '../modules/slice';
import { useDispatch } from 'react-redux';

const cx = classNames.bind(styles);

const ChangePasswordModal = (props) => {

    const dispatch = useDispatch();
    const { isOpen, onCloseModal } = props;

    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');

    const [showCurrentPassword, setShowCurrentPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCheck, setShowPasswordCheck] = useState(false);

    const [errors, setErrors] = useState({
        currentPassword: "",
        password: "",
        passwordCheck: "",
    });

    useEffect(() => {

    }, [isOpen]);

    const handleChangeCurrentPassword = e => {
        setCurrentPassword(e.target.value);
    }

    const handleChangePassword = e => {
        setPassword(e.target.value);
    }

    const handleChangePasswordCheck = e => {
        setPasswordCheck(e.target.value);
    }

    const validate = () => {
        const errors = {
            currentPassword: "",
            password: "",
            passwordCheck: "",
        };

        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,16}$/;
    
        if (!currentPassword || !passwordRegex.test(currentPassword)) {
            errors.currentPassword = "비밀번호를 다시 확인해주세요.";
        }
        if (!password || !passwordRegex.test(password)) {
            errors.password = "비밀번호를 다시 확인해주세요.";
        }
        if (!passwordCheck || password !== passwordCheck) {
            errors.passwordCheck = "비밀번호가 일치하지 않습니다.";
        }
    
        return errors;
    }

    const handleSubmit = e => {
        e.preventDefault()

        const errors = validate();
        // 에러 값을 설정하고
        setErrors(errors);
        // 잘못된 값이면 제출 처리를 중단한다.
        if (Object.values(errors).some(v => v)) {
          return;
        }

        dispatch(changePassword({currentPassword, password}));
        onCloseModal();
    }

    return (
        <div className={cx("modal-background")}>
            <div className={cx("modal-background-color", "blocked")} onClick={() => onCloseModal()} />
            <div className={cx("modal-panel")}>
                <div className={cx("modal-headline")}>
                    <h1 className={cx("modal-title")}>비밀번호 수정</h1>
                    <button className={cx("modal-close")} onClick={() => onCloseModal()}>
                        <Cross
                            width={24}
                            height={24}
                        />
                    </button>
                </div>
                <form className={cx("change-password-form-field")} onSubmit={handleSubmit}>
                    <div className={cx("list-item-full")}>
                        <div className={cx("input-title")}>현재 비밀번호</div>
                        <div className={cx("list-item-full", "password-input-field")}>
                            <input className={cx("list-item-full", "password-input")}
                                type={showCurrentPassword ? "text" : "password"}
                                name="currentPassword"
                                value={currentPassword}
                                placeholder='현재 비밀번호를 입력'
                                onChange={handleChangeCurrentPassword}
                            />
                            {showCurrentPassword ? <Visible
                                className={cx("toggle-show-password-button")}
                                onClick={() => setShowCurrentPassword(show => !show)}
                            /> : <Unvisible
                                className={cx("toggle-show-password-button")}
                                onClick={() => setShowCurrentPassword(show => !show)}
                            />}
                            {errors.currentPassword && <div className={cx("input-error-text")}><span>{errors.currentPassword}</span></div>}
                        </div>
                    </div>
                    <div className={cx("list-item-full")}>
                        <div className={cx("input-title")}>비밀번호</div>
                        <div className={cx("list-item-full", "password-input-field")}>
                            <input className={cx("list-item-full", "password-input")}
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={password}
                                placeholder='영문, 숫자, 특수문자 포함 8자리 이상'
                                onChange={handleChangePassword}
                            />
                            {showPassword ? <Visible
                                className={cx("toggle-show-password-button")}
                                onClick={() => setShowPassword(show => !show)}
                            /> : <Unvisible
                                className={cx("toggle-show-password-button")}
                                onClick={() => setShowPassword(show => !show)}
                            />}
                            {errors.password && <div className={cx("input-error-text")}><span>{errors.password}</span></div>}
                        </div>
                    </div>
                    <div className={cx("list-item-full")}>
                        <div className={cx("input-title")}>비밀번호 확인</div>
                        <div className={cx("list-item-full", "password-input-field")}>
                            <input className={cx("list-item-full", "password-input")}
                                type={showPasswordCheck ? "text" : "password"}
                                name="passwordCheck"
                                value={passwordCheck}
                                placeholder='비밀번호 확인'
                                onChange={handleChangePasswordCheck}
                            />
                            {showPasswordCheck ? <Visible
                                className={cx("toggle-show-password-button")}
                                onClick={() => setShowPasswordCheck(show => !show)}
                            /> : <Unvisible
                                className={cx("toggle-show-password-button")}
                                onClick={() => setShowPasswordCheck(show => !show)}
                            />}
                            {errors.passwordCheck && <div className={cx("input-error-text")}><span>{errors.passwordCheck}</span></div>}
                        </div>
                    </div>
                    <div className={cx("list-item-full")}>
                        <button className={cx("list-item-full", "confirm-button", "primary-button")} type="submit">비밀번호 변경하기</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChangePasswordModal;