import React from 'react';
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';

const cx = classNames.bind(styles);

const MobileStampCount = (props) => {

    const { stampCount, count } = props;

    const getCouponCostLabel = (c) => {
        let costLabel = '';
        switch (c) {
            case 10: costLabel = '500원 할인'; break;
            case 15: costLabel = '700원 할인'; break;
            case 20: costLabel = '1,000원 할인'; break;
            case 25: costLabel = '1,500원 할인'; break;
            case 30: costLabel = '1회 무료'; break;
        }

        return costLabel;
    }

    return (
        <li className={cx("stamp-bar-count-list-item")}>
            <div className={cx("stamp-bar-count-list-item-title")}>
                {count}개
            </div>
            <div className={cx(stampCount >= count ? "stamp-bar-count-coupon-active" : "stamp-bar-count-coupon")}>
                {getCouponCostLabel(count)}
            </div>
        </li>
    );
};

export default MobileStampCount;