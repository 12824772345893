import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';

const cx = classNames.bind(styles);

const Title = (props) => {

    const {title, children} = props;

    return (
        <div className={cx("main-title-view")}>
            <h1 className={cx("main-title")}>
                {title}
            </h1>
            {children}
        </div>
    );
}

export default Title;