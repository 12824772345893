import React from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const DetergentDetailItem = (props) => {

    const { imgSrc, itemName, list } = props;

    return (
        <div className={cx("detergent-detail-list")}>
            <div className={cx("detergent-detail-item-title")}>
                <img src={imgSrc ?? defaultImage} onError={handleImageError} width={16} height={16}/>
                <div>{itemName}</div>
            </div>
            {list?.map((item, index) => <div id={index} className={cx("detergent-detail-item")}>
                <div className={cx("detergent-detail-item-brand")}>
                    {item.brand_name}
                </div>
                <div className={cx("detergent-detail-item-name")}>
                    - {item.title}
                </div>
            </div>)}
        </div>
    );
};

export default DetergentDetailItem;