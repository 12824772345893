import React, { useEffect, useState } from 'react';
import DetergentItem from './DetergentItem';
import DetergentListFilters from './DetergentListFilters';
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getDetergentList } from '../modules/slice';

const cx = classNames.bind(styles);

const DetergentList = () => {

    const dispatch = useDispatch();
    const { detergents = [] } = useSelector(state => state.detergent);

    const [filter, setFilter] = useState('');

    useEffect(() => {
        dispatch(getDetergentList({productType: filter}));
    }, []);
    
    const handleChangeFilter = (filterName) => {
        setFilter(filterName);
        dispatch(getDetergentList({productType: filterName}));
    }

    return (
        <div className={cx("detergent-list-view")}>
            <DetergentListFilters filter={filter} onChangeFilter={handleChangeFilter}/>
            <ul className={cx("detergent-list")}>
                {!!detergents ? detergents.map((item) => {
                    return <li className={cx("detergent-list-item")}>
                        <DetergentItem detergent={item} />
                    </li>
                }) : ''}
            </ul>
        </div>
    );
};

export default DetergentList;