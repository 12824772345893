import React, { useEffect } from 'react';
import QuestionList from './QuestionList';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch } from 'react-redux';
import { getFaqList } from '../modules/slice';

const cx = classNames.bind(styles);

const Question = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFaqList({pageNum: 1}));
    }, []);

    return (
        <div className={cx("question-table-view")}>
            <QuestionList />
        </div>
    );
};

export default Question;