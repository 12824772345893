import React from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

const HowToUseBanner = () => {
    return (
        <div className={cx("home-banner")}>
            <div className={cx("home-banner-title")}>친환경 세제 리필 머신<br/>그린필박스</div>
            <div className={cx("home-banner-desc")}>필요한 것만 필요한 만큼 사는 새로운 리필 경험</div>
            <div className={cx("how-to-use-banner-list")}>
                <Link className={cx("how-to-use-panel", "left-panel")} to="/how-to-use">
                    <div className={cx("how-to-use-panel-title")}>{"이용방법 ->"}</div>
                    <div className={cx("how-to-use-panel-desc")}>세제 리필부터 리필 용기까지<br/>그린필박스 간편하게 이용하세요.</div>
                    <img className={cx("how-to-use-panel-image")} src="/img/how-to-use.png" />
                    <div className={cx("how-to-use-panel-cover")} />
                </Link>
                <Link className={cx("how-to-use-panel", "right-panel")} to="/detergents">
                    <div className={cx("how-to-use-panel-title")}>{"세제 정보 ->"}</div>
                    <div className={cx("how-to-use-panel-desc")}>그린필박스가 엄선한 친환경 세제 정보를<br/>꼼꼼하게 확인할 수 있어요.</div>
                    <img className={cx("how-to-use-panel-image")} src="/img/detergent-info.png" />
                    <div className={cx("how-to-use-panel-cover")} />
                </Link>
            </div>
        </div>
    );
};

export default HowToUseBanner;