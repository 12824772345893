import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import DropdownMenu from './DropdownMenu';
import {ReactComponent as Logo} from '../../images/logo.svg';
import {ReactComponent as DownArrow} from '../../icons/pull-down.svg';
import {ReactComponent as ListIcon} from '../../icons/list.svg';
import {ReactComponent as Cross} from '../../icons/cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { initEvent } from '../../events/modules/slice';
import { initLetter } from '../../letters/modules/slice';
import { initNotice } from '../../noticeBoard/modules/slice';
import { initReviews } from '../../reviews/modules/slice';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH } from '../../utils/constants';
import Accordion from '../../ui/components/Accordion';

const cx = classNames.bind(styles);

const Header = (props) => {
    const location = useLocation();
    const accessToken = localStorage.getItem("accessToken");
    const { pulldownTitle = "그린필박스 소개" } = props;
    const { eventInitialPage } = useSelector(state => state.event);
    const { letterInitialPage } = useSelector(state => state.letter);
    const { noticeInitialPage } = useSelector(state => state.notice);
    const { reviewInitialPage } = useSelector(state => state.review);
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });
    

    const dropMenuRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [openSideMenu, setOpenSideMenu] = useState(false);

    useEffect(() => {
        if (!!location?.pathname) {
            if (eventInitialPage !== 1 && !location?.pathname?.includes('/event')) {
                dispatch(initEvent());
            }
            if (letterInitialPage !== 1 && !location?.pathname?.includes('/letter')) {
                dispatch(initLetter());
            }
            if (noticeInitialPage !== 1 && !location?.pathname?.includes('/notice')) {
                dispatch(initNotice());
            }
            if (reviewInitialPage !== 1 && !location?.pathname?.includes('/review')) {
                dispatch(initReviews());
            }
        }
    }, [location]);

    useEffect(() => {
        const handleOutsideClose = (e) => {
            // useRef current에 담긴 엘리먼트 바깥을 클릭 시 드롭메뉴 닫힘
            if(!!dropMenuRef && !dropMenuRef.current.contains(e.target)) setOpen(false);
        };
        if (open) {
            document.addEventListener('click', handleOutsideClose);
        }
        
        return () => document.removeEventListener('click', handleOutsideClose);
    }, [open]);

    const checkRefresh = (url) => {
        if (location.pathname === url) {
            window.location.reload();
        }
    }

    const handleLogoutButtonClick = () => {
        setOpenSideMenu(false);
        localStorage.setItem("accessToken", "");
    }

    return (
        <>
            <header className={cx('header')}>
                <div className={cx('header-contents')}>
                    <Link className={cx('header-left')} to="/" onClick={() => setOpenSideMenu(false)}>
                        <Logo className={cx('header-logo')} />
                    </Link>

                    {!isMobile ? <div className={cx('header-right')}>
                        <div className={cx('header-menu-list')}>
                            <a ref={dropMenuRef} className={cx('header-menu')} href="#" onClick={() => setOpen(!open)}>
                                그린필박스 소개
                                <DownArrow className={cx('header-pull-down')} />
                                {open && <DropdownMenu />}
                            </a>
                            <Link className={cx('header-menu')} to="/notice" onClick={() => checkRefresh("/notice")}>
                                공지사항
                            </Link>
                            <Link className={cx('header-menu')} to="/event" onClick={() => checkRefresh("/event")}>
                                이벤트
                            </Link>
                            <Link className={cx('header-menu')} to="/letter" onClick={() => checkRefresh("/letter")}>
                                레터
                            </Link>
                            <Link className={cx('header-menu')} to="/questions" onClick={() => checkRefresh("/questions")}>
                                자주하는 질문
                            </Link>
                            <Link className={cx('header-menu')} to="/reviews" onClick={() => checkRefresh("/reviews")}>
                                후기
                            </Link>
                        </div>
                        {!!accessToken ? <div className={cx('header-user')}>
                            <Link className={cx('header-login')} to="/" onClick={() => handleLogoutButtonClick()}>
                                <button className={cx('header-logout-button')}>로그아웃</button>
                            </Link>
                            <Link className={cx('header-login')} to="/my-page" onClick={() => checkRefresh("/my-page")}>
                                <button className={cx("outline-gray-button", "mypage-button")}>마이페이지</button>
                            </Link>
                        </div> : <div className={cx('header-user')}>
                            <Link className={cx('header-login')} to="/login" onClick={() => checkRefresh("/login")}>
                                <button className={cx('header-login-button')}>로그인</button>
                            </Link>
                            <Link to="/register" onClick={() => checkRefresh("/register")}>
                                <button className={cx("primary-button", "header-register-button")}>회원가입</button>
                            </Link>
                        </div>}
                    </div> : <div className={cx('header-right-mobile')}>
                        <button className={cx("header-list-button")} onClick={() => setOpenSideMenu(!openSideMenu)}>
                            {!openSideMenu ? <ListIcon /> : <Cross />}
                        </button>
                    </div>}
                </div>
                {isMobile && <div className={cx('header-side-menu', openSideMenu ? 'opened' : '')}>
                    <div className={cx('header-side-menu-view')}>
                        <div className={cx('header-menu-list')}>
                            <Accordion header={
                                <div className={cx('header-menu')}>
                                    그린필박스 소개
                                </div>
                            }>
                                <DropdownMenu />
                            </Accordion>
                            <Link className={cx('header-menu')} to="/notice" onClick={() => checkRefresh("/notice")}>
                                공지사항
                            </Link>
                            <Link className={cx('header-menu')} to="/event" onClick={() => checkRefresh("/event")}>
                                이벤트
                            </Link>
                            <Link className={cx('header-menu')} to="/letter" onClick={() => checkRefresh("/letter")}>
                                레터
                            </Link>
                            <Link className={cx('header-menu')} to="/questions" onClick={() => checkRefresh("/questions")}>
                                자주하는 질문
                            </Link>
                            <Link className={cx('header-menu')} to="/reviews" onClick={() => checkRefresh("/reviews")}>
                                후기
                            </Link>
                        </div>
                        {!!accessToken ? <div className={cx('header-user')}>
                            <Link className={cx('header-login')} to="/" onClick={() => handleLogoutButtonClick()}>
                                <button className={cx('header-login-button')}>로그아웃</button>
                            </Link>
                            <Link className={cx('header-login')} to="/my-page" onClick={() => checkRefresh("/my-page")}>
                                <button className={cx("primary-button", "header-register-button")}>마이페이지</button>
                            </Link>
                        </div> : <div className={cx('header-user')}>
                            <Link className={cx('header-login')} to="/login" onClick={() => checkRefresh("/login")}>
                                <button className={cx('header-login-button')}>로그인</button>
                            </Link>
                            <Link to="/register" onClick={() => checkRefresh("/register")}>
                                <button className={cx("primary-button", "header-register-button")}>회원가입</button>
                            </Link>
                        </div>}
                    </div>
                </div>}
            </header>
            
        </>
    );
}

export default Header;