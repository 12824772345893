import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "../../utils/apiUtils";

export const GET_LETTERS = "letter/GET_LETTERS";
export const GET_LETTER_DETAIL = "letter/GET_LETTER_DETAIL";

export const getLetters = createAsyncThunk(
    GET_LETTERS,
    async ({pageNum}) => {

        const data = {page: pageNum}

        return getApi('/api/letters', data);
    },
);

export const getLetterDetail = createAsyncThunk(
    GET_LETTER_DETAIL,
    async ({id}) => {

        return getApi(`/api/letters/${id}`);
    },
);

const letterSlice = createSlice({
    name: 'letter',
    initialState: {
        errorMessage: '',
        letters: [],
        maxPage: 0,
        letterInitialPage: 1,
        detail: '',
    },
    reducers: {
        initLetter(state, action) {
            state.letterInitialPage = 1;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getLetters.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                state.letters = [];
                state.maxPage = 0;
                state.letterInitialPage = 1;
            }
            else {
                state.errorMessage = '';
                state.letters = action.payload.result;
                state.maxPage = action.payload.total_page;
                state.letterInitialPage = action.meta.arg.pageNum;
            }
        })

        .addCase(getLetterDetail.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.detail = action.payload;
            }
        })
    }
});

export const { initLetter } = letterSlice.actions;
export default letterSlice.reducer;