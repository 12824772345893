import React, { useEffect, useState } from 'react';
import ReviewComponent from './ReviewComponent';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getReviews } from '../modules/slice';
import PageNumbers from '../../ui/components/PageNumbers';
import {ReactComponent as EmptyReview} from '../../images/empty.svg';

const cx = classNames.bind(styles);

const ReviewList = () => {

    const dispatch = useDispatch();
    const { reviews, maxPage, updated } = useSelector(state => state.review);

    const [currPage, setCurrPage] = useState(1);

    useEffect(() => {
        dispatch(getReviews({pageNum: 1}));
    }, []);

    useEffect(() => {
        if (updated) {
            dispatch(getReviews({pageNum: currPage}));
        }
    }, [updated]);

    const handleChangePage = (page) => {
        setCurrPage(page);
        dispatch(getReviews({pageNum: page}));
    }

    return (
        <>
            <div className={cx("review-list-view")}>
                {(!!reviews && reviews.length > 0) ?
                    reviews.map((review) => {
                        return (
                            <ReviewComponent review={review} setCurrPage={setCurrPage} />
                        );
                    }) : <div className={cx("review-list-empty")}>
                        <EmptyReview className={cx("review-list-empty-image")} />
                        <div className={cx("review-list-empty-text")}>아직 후기가 없어요.<br/>첫번째 후기를 작성해보세요!</div>
                    </div>
                }
            </div>
            <PageNumbers currPage={currPage} maxPage={maxPage} onChangePage={handleChangePage} />
        </>
    );
};

export default ReviewList;