import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const OPEN_LOGIN_MODAL = "ui/OPEN_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "ui/CLOSE_LOGIN_MODAL";
export const OPEN_ERROR_MODAL = "ui/OPEN_ERROR_MODAL";
export const CLOSE_ERROR_MODAL = "ui/CLOSE_ERROR_MODAL";
export const CLOSE_ALL_MODAL = "ui/CLOSE_ALL_MODAL";

export const openLoginModal = createAsyncThunk(
    OPEN_LOGIN_MODAL,
    () => {},
);

export const closeLoginModal = createAsyncThunk(
    CLOSE_LOGIN_MODAL,
    () => {},
);

export const openErrorModal = createAsyncThunk(
    OPEN_ERROR_MODAL,
    ({message}) => {},
);

export const closeErrorModal = createAsyncThunk(
    CLOSE_ERROR_MODAL,
    () => {},
);

export const closeAllModal = createAsyncThunk(
    CLOSE_ALL_MODAL,
    () => {},
);

const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        isOpenLoginModal: false,
        isOpenErrorModal: false,
        errorMessage: '',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(openLoginModal.fulfilled, (state, action) => {
            state.isOpenLoginModal = true;
        })

        .addCase(closeLoginModal.fulfilled, (state, action) => {
            state.isOpenLoginModal = false;
        })

        .addCase(openErrorModal.fulfilled, (state, action) => {
            state.isOpenErrorModal = true;
            state.errorMessage = action.meta.arg.message;
        })

        .addCase(closeErrorModal.fulfilled, (state, action) => {
            state.isOpenErrorModal = false;
        })

        .addCase(closeAllModal.fulfilled, (state, action) => {
            state.isOpenLoginModal = false;
            state.isOpenErrorModal = false;
        })
    }
});

export default uiSlice.reducer;