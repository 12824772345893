import React from 'react';
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { handleImageError } from '../../utils/imageUtils';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH } from '../../utils/constants';

const cx = classNames.bind(styles);

const Introduction = () => {

    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

    return (
        <>
            {!isMobile ?
                <img onError={handleImageError} className={cx("introduction-image")} src="https://grfimgbucket.s3.ap-northeast-2.amazonaws.com/introduction.png" /> :
                <>
                    <img onError={handleImageError} className={cx("introduction-image")} src="/img/sub1-mo3x-1.png" />
                    <img onError={handleImageError} className={cx("introduction-image")} src="/img/sub1-mo3x-2.png" />
                </>
            }
        </>
    );
};

export default Introduction;