import React from 'react';
import DetergentItem from './DetergentItem';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch } from 'react-redux';
import { openErrorModal, openLoginModal } from '../../ui/modules/slice';
import { openDetailModal, setFavoriteStore } from '../modules/slice';
import { getTimeString } from '../../utils/datetimeUtils';
import { getUserInfo } from '../../myPage/modules/slice';
import { HttpStatusCode } from 'axios';

const cx = classNames.bind(styles);

const SearchItem = (props) => {

    const { item } = props;

    const dispatch = useDispatch();

    const accessToken = localStorage.getItem("accessToken");

    const handleChangeFavorite = e => {
        e.stopPropagation();
        if (!accessToken) {
            dispatch(openLoginModal());
            return;
        }
        dispatch(setFavoriteStore({id: item.id, favorite: !item.store_favorite, callback: (res) => {
            if (res.status === HttpStatusCode.Ok) {
                dispatch(getUserInfo());
            }
            else {
                dispatch(openErrorModal({message: res.data.message}));
            }
        }}));
    }

    const onOpenDetailModal = (storeId) => {
        dispatch(openDetailModal(storeId));
    }

    const getCount = (type) => {
        if (!!item) {
            const typeItem = item.product_types.filter(t => t.product_type === type);
            if (typeItem.length > 0) {
                return typeItem[0].count;
            }
        }

        return 0;
    }

    return (
        <div className={cx("search-list-item")}>
            <div className={cx("search-item-title-field")}>
                <h3 className={cx("search-item-title")} onClick={() => onOpenDetailModal(item.id)}>{item && item.title}</h3>
                <div className={cx("search-item-favorite")} onClick={handleChangeFavorite}>
                    <img src={(item && item.store_favorite === true) ? "icons/favorite-on.svg" : "icons/favorite-off.svg"} />
                </div>
            </div>
            <p className={cx("search-item-desc")} onClick={() => onOpenDetailModal(item.id)}>{item && item.address}</p>
            <p className={cx("search-item-desc")} onClick={() => onOpenDetailModal(item.id)}>운영시간 {item && getTimeString(item.open_time)}~{item && getTimeString(item.close_time)}</p>
            <div className={cx("search-item-detergent-list")} onClick={() => onOpenDetailModal(item.id)}>
                <DetergentItem imgSrc="icons/laundry-detergent.svg" itemName="세탁세제" count={getCount("LAUNDRY_DETERGENT")} />
                <DetergentItem imgSrc="icons/fabric-softener.svg" itemName="섬유유연제" count={getCount("FABRIC_SOFTENER")} />
                <DetergentItem imgSrc="icons/dish-detergent.svg" itemName="주방세제" count={getCount("DISH_DETERGENT")} />
            </div>
        </div>
    );
};

export default SearchItem;