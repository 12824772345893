import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HomePage from '../home/pages/HomePage';
import IntroductionPage from '../introduction/pages/IntroductionPage';
import LoginPage from '../login/pages/LoginPage';
import NoticeBoardPage from '../noticeBoard/pages/NoticeBoardPage';
import EventListPage from '../events/pages/EventListPage';
import QuestionPage from '../questions/pages/QuestionPage';
import ReviewPage from '../reviews/pages/ReviewPage';
import LocatePage from '../locate/pages/LocatePage';
import HowToUsePage from '../howToUse/pages/HowToUsePage';
import DetergentListPage from '../detergents/pages/DetergentListPage';
import RegisterPage from '../registerUser/pages/RegisterPage';
import ResetPasswordPage from '../resetPassword/pages/ResetPasswordPage';
import NoticeDetailPage from '../noticeBoard/pages/NoticeDetailPage';
import MyPage from '../myPage/pages/MyPage';
import EventPage from '../events/pages/EventPage';
import ModifyUserInfoPage from '../modifyUserInfo/pages/ModifyUserInfoPage';
import UploadPage from '../uploadImage/pages/UploadPage';
import PurchaseHistoryPage from '../purchaseHistory/pages/PurchaseHistoryPage';
import PurchaseHistoryDetailPage from '../purchaseHistory/pages/PurchaseHistoryDetailPage';
import CouponListPage from '../coupons/pages/CouponListPage';
import WriteReviewPage from '../reviews/pages/WriteReviewPage';
import ModifyReviewPage from '../reviews/pages/ModifyReviewPage';
import LetterListPage from '../letters/pages/LetterListPage';
import LetterPage from '../letters/pages/LetterPage';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/introduction" element={<IntroductionPage />} />
                <Route path="/how-to-use" element={<HowToUsePage />} />
                <Route path="/locate" element={<LocatePage />} />
                <Route path="/notice" element={<NoticeBoardPage />} />
                <Route path="/event" element={<EventListPage />} />
                <Route path="/event/:id" element={<EventPage />} />
                <Route path="/letter" element={<LetterListPage />} />
                <Route path="/letter/:id" element={<LetterPage />} />
                <Route path="/questions" element={<QuestionPage />} />
                <Route path="/reviews" element={<ReviewPage />} />
                <Route path="/detergents" element={<DetergentListPage />} />
                <Route path="/notice/:id" element={<NoticeDetailPage />} />
                
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route path="/my-page" element={<MyPage />} />
                <Route path="/payment" element={<PurchaseHistoryPage />} />
                <Route path="/payment/:id" element={<PurchaseHistoryDetailPage />} />
                <Route path="/user/modify" element={<ModifyUserInfoPage />} />
                <Route path="/coupon" element={<CouponListPage />} />
                <Route path="/review/write/:paymentId" element={<WriteReviewPage />} />
                <Route path="/review/modify/:reviewId" element={<ModifyReviewPage />} />

                <Route path="/upload" element={<UploadPage />} />

                <Route path="*" element={<div>404 페이지를 찾을 수 없습니다.</div>} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;