import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getApi, postApi } from "../../utils/apiUtils";
import { DATETIME_FORMAT } from "../../utils/constants";

export const REQUEST_VERIFY = "registerUser/REQUEST_VERIFY"; // 번호인증요청
export const VERIFY_PHONE = "registerUser/VERIFY_PHONE"; // 번호인증
export const REGISTER_USER = "registerUser/REGISTER_USER"; // 회원가입

export const requestVerificationForRegister = createAsyncThunk(
    REQUEST_VERIFY,
    async ({phoneNumber}) => {

        const data = {phone: phoneNumber};
        
        return getApi('/api/signs/request-sign-up-sms', data);
    },
);

export const verifyForRegister = createAsyncThunk(
    VERIFY_PHONE,
    async ({phoneNumber, verificationCode}) => {

        const data = {phone: phoneNumber, verification_code: verificationCode};

        return postApi('/api/signs/verify-sign-up-phone-number', data);
    },
);

export const registerUser = createAsyncThunk(
    REGISTER_USER,
    async ({phone, password, name, email, birthDate, emailAgreement, smsAgreement}) => {

        const data = {
            phone,
            password,
            name,
            email,
            birth_date: birthDate,
            email_opt_in: emailAgreement,
            sms_opt_in: smsAgreement,
        };

        return postApi('/api/signs/sign-up', data);
    },
);

const registerUserSlice = createSlice({
    name: 'registerUser',
    initialState: {
        phoneNumber: '',
        userName: '',
        errorMessage: '',
        verifyState: 'none',
        requestTime: '',
    },
    reducers: {
        initRegister(state, action) {
            state.phoneNumber = '';
            state.userName = '';
            state.errorMessage = '';
            state.verifyState = 'none';
            state.requestTime = '';
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(requestVerificationForRegister.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.phoneNumber = action.meta.arg.phoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
                state.errorMessage = '';
                state.verifyState = 'verifying';
                state.requestTime =  moment().format(DATETIME_FORMAT);
            }
        })

        .addCase(verifyForRegister.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.verifyState = 'verified';
                state.requestTime = '';
            }
        })

        .addCase(registerUser.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                state.requestTime = '';
            }
            else {
                state.userName = action.meta.arg.name;
                state.errorMessage = '';
                state.verifyState = 'registered';
            }
        })
    }
});

export const { initRegister } = registerUserSlice.actions;
export default registerUserSlice.reducer;