import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import EventListItem from '../components/EventListItem';
import PageNumbers from '../../ui/components/PageNumbers';
import { useDispatch, useSelector } from 'react-redux';
import { getEvents } from '../modules/slice';

const cx = classNames.bind(styles);

const Events = () => {

    const dispatch = useDispatch();

    const { events, maxPage, eventInitialPage } = useSelector(state => state.event);

    const [currPage, setCurrPage] = useState(1);
    const [isInitial, setIsInitial] = useState(true);

    useEffect(() => {
        if (isInitial) {
            setCurrPage(eventInitialPage);
            dispatch(getEvents({pageNum: eventInitialPage}));
            setIsInitial(false);
        }
    }, [eventInitialPage]);

    const handleChangePage = (page) => {
        setCurrPage(page);
        dispatch(getEvents({pageNum: page}));
    }

    return (
        <div className={cx("event-view")}>
            <div className={cx("event-list-view")}>
                {!!events ? events.map((event) => {
                    return <EventListItem key={event.id} event={event} />;
                }) : ''}
            </div>
            <PageNumbers currPage={currPage} maxPage={maxPage} onChangePage={handleChangePage} />
        </div>
    );
};

export default Events;