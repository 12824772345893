import moment from "moment";
import { useRef, useEffect } from "react";
import { DATETIME_FORMAT } from "./constants";

export function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        // useEffect에 매개변수로 받은 콜백을 현재 Ref로 선언해준다.
        savedCallback.current = callback;
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        // useEffect에 Ref의 current를 setInterval를 delay 시간동안 해준다.
        let id = setInterval(tick, delay);
        // 언마운트되기전 clearInterval을 해준다.
        return () => clearInterval(id);
    }, [delay]);
}

export const getMoment = (dateTime) => {
    return !!dateTime ? moment(dateTime, DATETIME_FORMAT) : null;
}

export const getDateString = (dateTime, interval = '.') => {
    const timeMoment = getMoment(dateTime);
    return !!timeMoment ? timeMoment.format(`YYYY${interval}MM${interval}DD`) : '';
}

export const getTimeString = (dateTime) => {
    return dateTime && moment(dateTime).format("HH:mm");
}

export const getDDay = (dateTime) => {
    const timeMoment = moment(getDateString(dateTime), 'YYYY.MM.DD');
    const curr = moment();
    return moment.duration(timeMoment.diff(curr)).days() + 1;
}

export const checkBirthDate = (birthDate) => {
    const regex = /^\d{4}-(0[1-9]|[1-9]|1[012])-(0[1-9]|[1-9]|[12][0-9]|3[01])$/;
    if (!regex.test(birthDate)) {
        return false;
    }

    const date = Date.parse(birthDate);

    return !isNaN(date);
}