import React, { useEffect } from 'react';
import Layout from '../../layout/components/Layout';
import CouponInfo from '../components/CouponInfo';
import FavoriteList from '../components/FavoriteList';
import PurchaseHistory from '../components/PurchaseHistory';
import Title from '../../layout/components/Title';
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../modules/slice';
import { initReviews } from '../../reviews/modules/slice';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH } from '../../utils/constants';
import MobilePurchaseHistory from '../components/MobilePurchaseHistory';

const cx = classNames.bind(styles);

const MyPage = () => {

    const dispatch = useDispatch();
    const { updated } = useSelector(state => state.review);
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

    useEffect(() => {
        dispatch(getUserInfo());
    }, []);

    useEffect(() => {
        if (updated) {
            dispatch(getUserInfo());
            dispatch(initReviews());
        }
    }, [updated]);

    return (
        <Layout hasHeader={true} hasFooter={true} needToken={true}>
            <Title title="마이페이지">
                <div className={cx("my-page-view")}>
                    <CouponInfo />
                    <FavoriteList  />
                    {!isMobile ? <PurchaseHistory /> : <MobilePurchaseHistory />}
                </div>
            </Title>
        </Layout>
    );
};

export default MyPage;