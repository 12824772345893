import React from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import {ReactComponent as Logo} from '../../images/logo-white.svg';
import {ReactComponent as CallCenter} from '../../icons/kakaotalk.svg';

const cx = classNames.bind(styles);

const Footer = () => {
    return (
        <footer className={cx('footer')}>
            <div className={cx('footer-contents')}>
                <div className={cx('footer-logo')}>
                    <Logo className={cx('footer-logo-image')}/>
                </div>
                <div className={cx('footer-terms')}>
                    <a className={cx("footer-usage")} target="_blank" rel="noreferrer noopener" href='https://cypress-curiosity-65a.notion.site/Ver-1-f43ee3eecad94f429482b7339cbbfe89?pvs=4'>
                        <span>이용약관</span>
                    </a>
                    <div className={cx("vertical-divider")} />
                    <a className={cx("footer-usage")} target="_blank" rel="noreferrer noopener" href='https://cypress-curiosity-65a.notion.site/a20ec15b7f864bf1a9f886e259ebea76?pvs=4'>
                        <span>개인정보처리방침</span>
                    </a>
                    <div className={cx("vertical-divider")} />
                    <a className={cx("footer-usage")} target="_blank" rel="noreferrer noopener" href='https://cypress-curiosity-65a.notion.site/908d01f4efbd4afc814784c4065b6881?pvs=4'>
                        <span>위치기반서비스이용약관</span>
                    </a>
                </div>
                <div className={cx('footer-info')}>
                    <span>대표:김지숙</span>
                    <span>사업자등록번호:418-88-02925</span>
                </div>
            </div>
            <div className={cx('footer-second-contents')}>
                <div className={cx('footer-call-center-header')}>
                    <span className={cx('footer-call-center-title')}>고객센터</span>
                    <span className={cx('footer-call-center-content')}>070-4667-4020</span>
                    <span className={cx('footer-call-center-time')}>월~금 오전 11시 ~ 오후 4시</span>
                </div>
                <a href="http://pf.kakao.com/_uxaNAG/chat" target="_blank" rel="noreferrer noopener">
                    <button className={cx('outline-gray-button', 'footer-call-center-button')}>
                        <div className={cx('footer-call-center-button-content')}>
                            <span className={cx('footer-call-center-button-text')}>1:1 문의하기</span>
                            <CallCenter />
                        </div>
                    </button>
                </a>
            </div>
        </footer>
    );
}

export default Footer;