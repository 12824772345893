import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {ReactComponent as CouponImage} from '../../images/coupon.svg';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';

const cx = classNames.bind(styles);

const Welcome = () => {

    const { userName } = useSelector(state => state.registerUser);

    return (
        <div className={cx("register-view")}>
            <div className={cx("list-item-full", "register-complete-view")}>                
                <CouponImage className={cx("complete-image")} />
                <h1 className={cx("welcome-title")}><span className={cx("welcome-user-name")}>{userName}</span>님 가입을 축하해요!</h1>
                <p className={cx("welcome-desc")}>웰컴 쿠폰을 지급해드렸어요.<br/>쿠폰 상세 내용은 마이페이지&#62;쿠폰/스탬프에서 확인하세요.</p>
                <Link className={cx("confirm-button-field")} to="/login">
                    <button className={cx("list-item-full", "primary-button")}>로그인</button>
                </Link>
            </div>
        </div>
    );
};

export default Welcome;