import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { initLogin, signIn } from '../modules/slice';
import {ReactComponent as CouponImage} from '../../images/coupon.svg';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';

const cx = classNames.bind(styles);

const Login = () => {

    const { refresh, errorMessage } = useSelector(state => state.login);
    const accessToken = localStorage.getItem('accessToken');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState('');

    useEffect(() => {
        dispatch(initLogin());
    }, []);

    useEffect(() => {
        if (!!accessToken) {
            navigate('/', {replace: true});
        }
    }, [navigate, accessToken, refresh]);

    useEffect(() => {
        setError(errorMessage);
    }, [errorMessage]);

    const handleChangePhoneNumber = e => {

        const phone = e.target.value;

        setPhoneNumber(phone
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`));
    }

    const handleChangePassword = e => {
        setPassword(e.target.value);
    }

    const validate = () => {

        if (!phoneNumber || !password) {
            return '로그인 정보를 확인하세요';
        }

        return '';
    }

    const handleSubmit = e => {
        e.preventDefault()

        const errors = validate();
        // 에러 값을 설정하고
        setError(errors ? errors : errorMessage);
        // 잘못된 값이면 제출 처리를 중단한다.
        if (!!errors) {
          return;
        }

        dispatch(signIn({phoneNumber: phoneNumber.replace(/[^0-9]/g, ''), password: password}));
    }

    return (
        <div className={cx("login-view")}>
            <h1 className={cx("login-title")}>로그인</h1>
            <div className={cx("list-item-full")}>
                <form onSubmit={handleSubmit}>
                    <div className={cx("list-item-full", "login-input-field")}>
                        <input className={cx("list-item-full")}
                            type="text"
                            name="phoneNumber"
                            value={phoneNumber}
                            placeholder='휴대폰 번호(-없이 숫자만 입력)'
                            onChange={handleChangePhoneNumber}
                            maxLength={13}
                        />
                    </div>
                    <div className={cx("list-item-full", "login-input-field")}>
                        <input className={cx("list-item-full")}
                            type="password"
                            name="password"
                            value={password}
                            placeholder='비밀번호'
                            onChange={handleChangePassword}
                        />
                    </div>
                    {error && <div className={cx("input-error-text", "login-error-text")}><span>{error}</span></div>}
                    <div className={cx("list-item-full")}>
                        <button className={cx("list-item-full", "primary-button", "login-button")} type="submit">로그인</button>
                    </div>
                </form>
                <div className={cx("list-item-full")}>
                    <Link className={cx("text-link-button")} to="/reset-password"><span className={cx("text-link-button-inner")}>비밀번호 재설정</span></Link>
                </div>
                <div className={cx("login-divider")} />
                <CouponImage className={cx('login-coupon-image')} />
                <div className={cx("list-item-full", "login-tooltip")}>
                    <span>회원가입하고 쿠폰 스탬프를 적립하세요!</span>
                </div>
                <div className={cx("list-item-full", "login-register-button")}>
                    <Link to="/register">
                        <button className={cx("list-item-full", "outline-gray-button")}>회원가입</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Login;