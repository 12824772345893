import React from 'react';
import Layout from '../../layout/components/Layout';
import EventDetail from '../components/EventDetail';

const EventPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <EventDetail />
        </Layout>
    );
};

export default EventPage;