import React, { useState } from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as SearchIcon} from '../../icons/search.svg';

const cx = classNames.bind(styles);

const SearchBanner = () => {

    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState('');

    const handleChangeSearchValue = e => {
        setSearchValue(e.target.value);
    }

    const onKeyDown = e => {
        if (e.key === 'Enter') {
            navigate("/locate", {
                state: {searchValue: searchValue}
            });
        }
    }

    return (
        <div className={cx("search-banner")}>
            <div className={cx("search-banner-title")}>그린필박스 위치 찾기</div>
            <div className={cx("search-banner-input-field")}>
                <input className={cx("search-banner-input")}
                    name="searchValue"
                    value={searchValue}
                    placeholder='매장명 또는 주소를 입력하세요.'
                    onChange={handleChangeSearchValue}
                    onKeyDown={onKeyDown}
                />
                <SearchIcon className={cx("search-icon")} />
            </div>
            <div className={cx("search-banner-cover")} />
        </div>
    );
};

export default SearchBanner;