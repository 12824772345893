import React, { useEffect, useRef } from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';

const cx = classNames.bind(styles);

const DropdownFilter = (props) => {

    const { isOpen, setOpen, filters, setFilters } = props;

    const dropMenuRef = useRef(null);

    useEffect(() => {

        const handleOutsideClose = (e) => {
            // useRef current에 담긴 엘리먼트 바깥을 클릭 시 드롭메뉴 닫힘
            if (isOpen && dropMenuRef.current && !dropMenuRef.current.contains(e.target)) {
                e.stopPropagation();
                setOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mouseup', handleOutsideClose);
        }
        
        return () => document.removeEventListener('mouseup', handleOutsideClose);
    }, [isOpen]);


    const handleChangeFilter = (idx, check) => {
        setFilters(idx, check);
    }

    return (
        <div ref={dropMenuRef} className={cx("dropdown-list")}>
            {filters.map((filter, i) => (
                <div key={`filter-item-${filter.id}`} className={cx("dropdown-menu", "checkbox-input-field")}>
                    <input id={`filter-${filter.id}`}
                        className={cx("checkbox")}
                        type="checkbox"
                        checked={filter.value}
                        onChange={({ target: { checked } }) => handleChangeFilter(i, checked)}
                    />
                    <label for={`filter-${filter.id}`} className={cx("checkbox-label")}>
                        <span>{filter.name}</span>
                    </label>
                </div>
            ))}
        </div>
    );
}

export default DropdownFilter;