import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi, postApi, deleteApi } from "../../utils/apiUtils";

export const GET_BRAND_FILTER = "locate/GET_BRAND_FILTER";
export const CHANGE_FILTER_VALUE = "locate/CHANGE_FILTER_VALUE";
export const SEARCH_GREENFILL = "locate/SEARCH_GREENFILL";
export const GET_GREENFILL_DETAIL = "locate/GET_GREENFILL_DETAIL";
export const SET_FAVORITE_STORE = 'locate/TOGGLE_FAVORITE_STORE';
export const INIT_REFRESH_MAP = 'locate/INIT_REFRESH_MAP';
export const OPEN_DETAIL_MODAL = "locate/OPEN_DETAIL_MODAL";
export const CLOSE_DETAIL_MODAL = "locate/CLOSE_DETAIL_MODAL";

export const getBrandFilter = createAsyncThunk(
    GET_BRAND_FILTER,
    async () => {

        const data = {};
        
        return getApi('/api/brands');
    },
);

export const changeFilterValue = createAsyncThunk(
    CHANGE_FILTER_VALUE,
    ({filterName, value, brandIndex}) => {

        return {filterName, value, brandIndex};
    },
);

export const searchGreenfill = createAsyncThunk(
    SEARCH_GREENFILL,
    async ({laundryFilter, fabricFilter, dishFilter, brandFilter, searchValue, tlx, tly, brx, bry}) => {

        const productTypes = [];
        laundryFilter && productTypes.push("LAUNDRY_DETERGENT");
        fabricFilter && productTypes.push("FABRIC_SOFTENER");
        dishFilter && productTypes.push("DISH_DETERGENT");
        const brands = brandFilter.filter((brand) => brand.value && brand.id !== 0).map((brand) => {
            return brand.name;
        });
        const data = {
            query: searchValue,
            product_types: productTypes,
            brands: brands,
            top_left_x: tlx,
            top_left_y: tly,
            bottom_right_x: brx,
            bottom_right_y: bry,
        };

        return getApi('/api/stores', data);
    },
);

export const getGreenfillDetail = createAsyncThunk(
    GET_GREENFILL_DETAIL,
    async ({id}) => {

        const data = {
            id,
        };

        return postApi('/api/search/detail', data);
    },
);

export const setFavoriteStore = createAsyncThunk(
    SET_FAVORITE_STORE,
    async ({id, favorite, callback}) => {
        
        if (favorite) {
            return postApi(`/api/stores/${id}/favorite`, {}, null, callback);
        } else {
            return deleteApi(`/api/stores/${id}/unfavorite`, {}, null, callback);
        }
    },
);

export const initRefreshMap = createAsyncThunk(
    INIT_REFRESH_MAP,
    () => {},
);

export const openDetailModal = createAsyncThunk(
    OPEN_DETAIL_MODAL,
    async (storeId) => {

        return getApi(`/api/stores/${storeId}`);
    },
);

export const closeDetailModal = createAsyncThunk(
    CLOSE_DETAIL_MODAL,
    () => {},
);

const locateSlice = createSlice({
    name: 'locate',
    initialState: {
        errorMessage: '',
        laundryFilter: false,
        fabricFilter: false,
        dishFilter: false,
        brandFilter: [],
        searchResults: [],
        refreshMap: false,
        isOpenDetailModal: false,
        storeDetail: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getBrandFilter.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.brandFilter = [];
            }
            else {
                let brands = [{id: 0, name: "전체", value: true}];
                state.brandFilter = brands.concat(action.payload.map((item) => {
                    item.value = true;
                    return item;
                }));
            }
        })

        .addCase(changeFilterValue.fulfilled, (state, action) => {
            const filterName = action.payload.filterName;
            switch (filterName) {
                case "laundry":
                    state.laundryFilter = action.payload.value;
                    break;
                case "fabric":
                    state.fabricFilter = action.payload.value;
                    break;
                case "dish":
                    state.dishFilter = action.payload.value;
                    break;
                case "brand":
                    if (action.payload.brandIndex === 0) {
                        state.brandFilter = state.brandFilter.map((filter, index) => {
                            filter.value = action.payload.value;
                            return filter;
                        });
                    }
                    else {
                        const value = action.payload.value;
                        state.brandFilter[action.payload.brandIndex].value = value;
                        if (state.brandFilter[0].value !== value) {
                            if (!value || state.brandFilter.every((filter, index) => filter.value === value || index === 0)) {
                                state.brandFilter[0].value = value;
                            }
                        }
                    }
                    
                    break;
            }
        })

        .addCase(searchGreenfill.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                state.searchResults = [];
            }
            else {
                if (!!action.meta.arg.tlx && !!action.meta.arg.tly && !!action.meta.arg.brx && !!action.meta.arg.bry) {
                    state.refreshMap = false;
                } else {
                    state.refreshMap = true;
                }
                state.errorMessage = '';
                if (state.brandFilter.filter((brand) => brand.value).length === 0) {
                    state.searchResults = [];
                } else {
                    state.searchResults = action.payload;
                }
            }
        })

        .addCase(getGreenfillDetail.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.detail = {};
            }
            else {
                state.detail = action.payload;
            }
        })

        .addCase(setFavoriteStore.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else if (state.searchResults.length > 0) {
                state.searchResults = state.searchResults.map((store) => {
                    if (store.id === action.meta.arg.id) {
                        store.store_favorite = action.meta.arg.favorite;
                    }
                    return store;
                });
                if (!!state.storeDetail && state.storeDetail.id === action.meta.arg.id) {
                    state.storeDetail.store_favorite = action.meta.arg.favorite;
                }
            }
        })

        .addCase(initRefreshMap.fulfilled, (state, action) => {
            state.refreshMap = false;
        })

        .addCase(openDetailModal.fulfilled, (state, action) => {
            state.storeDetail = action.payload;
            state.isOpenDetailModal = true;
        })

        .addCase(closeDetailModal.fulfilled, (state, action) => {
            state.isOpenDetailModal = false;
        })
    }
});

export default locateSlice.reducer;