import React from 'react';
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import SearchItem from '../../locate/components/SearchItem';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);

const FavoriteList = () => {

    const { myInfo } = useSelector(state => state.myPage);

    return (
        <div>
            <h3 className={cx("my-page-subtitle")}>나의 그린필박스</h3>
            <div className={cx("my-page-desc")}>자주 가는 매장을 저장하세요! 나의 그린필박스는 3개까지 저장 가능합니다.</div>
            <div className={cx("my-page-favorite-list")}>
                {(!!myInfo && !!myInfo.favorites) ? myInfo.favorites.map((favorite) => {
                    return <SearchItem item={favorite}/>;
                }) : ''}
            </div>
        </div>
    );
};

export default FavoriteList;