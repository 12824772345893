import React, { useState } from 'react';
import PageNumbers from '../../ui/components/PageNumbers';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import Accordion from '../../ui/components/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import { getFaqList } from '../modules/slice';

const cx = classNames.bind(styles);

const QuestionList = () => {

    const { list, maxPage } = useSelector(state => state.question);
    const dispatch = useDispatch();

    const [currPage, setCurrPage] = useState(1);

    const handleChangeQuestionPage = (page) => {
        setCurrPage(page);
        dispatch(getFaqList({pageNum: page}));
    }

    return (
        <>
            <ul className={cx("question-list-view")}>
                {
                    list.map(item => {
                        return <li className={cx("question-list-item")}>
                            <Accordion header={<div className={cx("question-item-view")}>
                                <div className={cx("question-item-filter-view")}><div className={cx("question-item-filter")}>{item.faq_category}</div></div>
                                <div className={cx("question-item-title")}>{item.question}</div>
                            </div>}>
                                <div className={cx("question-item-content")}>{item.answer}</div>
                            </Accordion>
                        </li>;
                    })
                }
            </ul>
            <PageNumbers currPage={currPage} maxPage={maxPage} onChangePage={handleChangeQuestionPage} />
        </>
    );
};

export default QuestionList;