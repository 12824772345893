import React from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { renderPrice, toHtml } from '../../utils/stringUtils';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const DetergentItem = (props) => {

    const { detergent } = props;

    return (
        <div className={cx("detergent-item-view")}>
            <div className={cx("detergent-item-image-view")}>
                <img className={cx("detergent-item-image")} onError={handleImageError} src={detergent.main_image_url ?? defaultImage} alt={detergent.title} />
                {!!detergent.tags[0] && <div className={cx("detergent-item-tag1")}>
                    {detergent.tags[0]}
                </div>}
                {(!!detergent && detergent.tags.length >= 2 && !!detergent.tags[1]) && <div className={cx("detergent-item-tag2")}>
                    {detergent.tags[1]}
                </div>}
            </div>
            <div className={cx("detergent-item-title-header")}>
                <div className={cx("detergent-item-title")}>
                    {detergent.title}
                </div>
                <div className={cx("detergent-item-subtitle")}>
                    {detergent.brand_name}
                </div>
            </div>
            <div className={cx("detergent-item-desc")} dangerouslySetInnerHTML={{__html: toHtml(detergent.description)}}>
            </div>
            <dl>
                <dt>기능</dt>
                <dd>{detergent.detergent_function ?? '-'}</dd>

                <dt>향</dt>
                <dd>{detergent.detergent_scent ?? '-'}</dd>

                <dt>브랜드명</dt>
                <dd>{detergent.brand_name ?? '-'}</dd>

                <dt>제조사명</dt>
                <dd>{detergent.manufacturer_name ?? '-'}</dd>

                <dt>성분</dt>
                <dd>{detergent.ingredient ?? '-'}</dd>
            </dl>
            <div className={cx("detergent-item-footer")}>
                <div className={cx("footer-title")}>최소 제공용량 당 가격</div>
                <div className={cx("footer-desc")}>{`${renderPrice(detergent.item_capacity)}ml / ${renderPrice(detergent.price)}원(ml당 ${renderPrice((detergent.price / detergent.item_capacity).toFixed(1))}원)`}</div>
            </div>
        </div>
    );
};

export default DetergentItem;