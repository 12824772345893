import React from 'react';
import Layout from '../../layout/components/Layout';
import Title from '../../layout/components/Title';
import NoticeBoard from '../components/NoticeBoard';

const NoticeBoardPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Title title={'공지사항'}>
                <NoticeBoard />
            </Title>
        </Layout>
    );
};

export default NoticeBoardPage;