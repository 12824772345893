import React, { Fragment } from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch } from 'react-redux';
import { getDateString } from '../../utils/datetimeUtils';
import { renderPrice } from '../../utils/stringUtils';
import { useNavigate } from 'react-router-dom';
import { deleteReview } from '../../reviews/modules/slice';
import { HttpStatusCode } from 'axios';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const PurchaseDetergentTable = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { detergents, paymentId, reviewId, reviewable } = props;

    const handleWriteReview = (id) => {
        navigate(`/review/write/${id}`);
    }

    const handleUpdateReview = (reviewId) => {
        navigate(`/review/modify/${reviewId}`);
    }

    const handleDeleteReview = (reviewId) => {
        dispatch(deleteReview({reviewId}));
    }

    return (
        <div className={cx("purchase-history-list")}>
            <table className={cx("purchase-history-table")}>
                <thead>
                    <tr>
                        <th>상품정보</th>
                        <th className={cx("purchase-manufacture-date-column")}>제조일자</th>
                        <th className={cx("purchase-bottle-column")}>보틀 구매</th>
                        <th className={cx("purchase-price-column")}>상품 가격</th>
                        <th className={cx("purchase-review-column")}></th>
                    </tr>
                </thead>
                <tbody>
                    {!!detergents ? detergents?.map((detergent, idx) => {
                        if (idx === 0) {
                            return <tr className={cx("purchase-history-row", detergents.length <= 1 ? "last-row": '')}>
                                <td>
                                    <Fragment>
                                        <div className={cx("purchase-item-cell", "first-row", detergents.length <= 1 ? "last-row": '')}>
                                            <img onError={handleImageError} src={detergent.product.thumb_image_url ?? defaultImage} width={64} height={64}/>
                                            <div className={cx("purchase-item-header")}>
                                                <div className={cx("purchase-item-title-line")}><span className={cx("purchase-item-title")}>{detergent.product.brand_name}</span><span className={cx("purchase-item-subtitle")}>{detergent.product.title}</span></div>
                                                <div className={cx("purchase-item-desc")}>{renderPrice(detergent.item_capacity)}ml</div>
                                            </div>
                                        </div>
                                    </Fragment>
                                </td>
                                <td className={cx("purchase-manufacture-date-cell", "first-row", detergents.length <= 1 ? "last-row": '')}>{getDateString(detergent.manufacture_date, '-')}</td>
                                <td className={cx("purchase-bottle-cell", detergent.bottle_purchased ? "active" : '', "first-row", detergents.length <= 1 ? "last-row": '')}>
                                    {detergent.bottle_purchased ?
                                        <Fragment><div><span>{'구매'}</span></div></Fragment>
                                        : '-'
                                    }
                                </td>
                                <td className={cx("purchase-price-cell", "first-row", detergents.length <= 1 ? "last-row": '')}>{renderPrice(detergent.product.price)}원</td>
                                <td rowSpan={detergents.length > 1 ? detergents.length : 1}>
                                    {!reviewable ? <Fragment>
                                        <div className={cx("button-group")}>
                                            <button className={cx("outline-gray-button", "table-button")} onClick={() => handleUpdateReview(reviewId)}>후기 수정</button>
                                            <button className={cx("outline-gray-button", "table-button")} onClick={() => handleDeleteReview(reviewId)}>후기 삭제</button>
                                        </div>
                                    </Fragment> : <button className={cx("outline-button", "table-button")} onClick={() => handleWriteReview(paymentId)}>
                                        후기 작성
                                    </button>}
                                </td>
                            </tr>;
                        }
                        else {
                            return <tr className={cx("purchase-history-row", detergents.length == idx + 1 ? "last-row" : '')}>
                                <td>
                                    <Fragment>
                                        <div className={cx("purchase-item-cell", detergents.length == idx + 1 ? "last-row" : '')}>
                                            <img onError={handleImageError} src={detergent.product.thumb_image_url ?? defaultImage} width={64} height={64}/>
                                            <div className={cx("purchase-item-header")}>
                                                <div className={cx("purchase-item-title-line")}><span className={cx("purchase-item-title")}>{detergent.product.brand_name}</span><span className={cx("purchase-item-subtitle")}>{detergent.product.title}</span></div>
                                                <div className={cx("purchase-item-desc")}>{renderPrice(detergent.item_capacity)}ml</div>
                                            </div>
                                        </div>
                                    </Fragment>
                                </td>
                                <td className={cx("purchase-manufacture-date-cell", detergents.length == idx + 1 ? "last-row" : '')}>{getDateString(detergent.manufacture_date, '-')}</td>
                                <td className={cx("purchase-bottle-cell", detergent.bottle_purchased ? "active" : '', detergents.length == idx + 1 ? "last-row" : '')}>
                                    {detergent.bottle_purchased ?
                                        <Fragment><div><span>{'구매'}</span></div></Fragment>
                                        : '-'
                                    }
                                </td>
                                <td className={cx("purchase-price-cell", detergents.length == idx + 1 ? "last-row" : '')}>{renderPrice(detergent.product.price)}원</td>
                            </tr>;
                        }
                        
                    }) : ''}
                </tbody>
            </table>
        </div>
    );
};

export default PurchaseDetergentTable;