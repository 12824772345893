import React, { useEffect, useState } from 'react';
import {ReactComponent as Cross} from '../../icons/cross.svg';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch } from 'react-redux';
import { deleteAccount } from '../modules/slice';

const cx = classNames.bind(styles);

const DeleteAccountModal = (props) => {

    const dispatch = useDispatch();

    const { isOpen, onCloseModal } = props;

    const [agreement, setAgreement] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setAgreement(false);
        }
    }, [isOpen])

    const handleChangeAgreement = (value) => {
        setAgreement(value);
    }

    const handleSubmit = () => {
        if (!agreement) {
            alert("탈퇴 안내사항에 동의해야 탈퇴 가능합니다.");
            return;
        }
        dispatch(deleteAccount());
        onCloseModal();
    }

    return (
        <div className={cx("modal-background")}>
            <div className={cx("modal-background-color", "blocked")} onClick={() => onCloseModal()} />
            <div className={cx("modal-panel")}>
                <div className={cx("modal-headline")}>
                    <h1 className={cx("modal-title")}>그린필박스 회원 탈퇴</h1>
                    <button className={cx("modal-close")} onClick={() => onCloseModal()}>
                        <Cross
                            width={24}
                            height={24}
                        />
                    </button>
                </div>
                <div className={cx("delete-account-content")}>
                    <p className={cx("delete-account-subtitle")}>탈퇴시 안내사항</p>
                    <ul className={cx("delete-account-policy-list")}>
                        <li className={cx("delete-account-policy-list-item")}>보유하고 계신 쿠폰 및 스탬프는 자동 소멸되며 복구되지 않습니다.</li>
                        <li className={cx("delete-account-policy-list-item")}>작성하신 리뷰는 자동삭제되지 않고 서비스 내 계속 표시될 수 있습니다.</li>
                    </ul>
                    <div className={cx("checkbox-input-field")}>
                        <input id="agreement"
                            className={cx("checkbox")}
                            type="checkbox"
                            checked={agreement}
                            onChange={({ target: { checked } }) => handleChangeAgreement(checked)}
                        />
                        <label for="agreement" className={cx("checkbox-label", "modal-checkbox-label")}>
                            <span>안내사항을 모두 확인했으며, 이에 동의합니다.</span>
                        </label>
                    </div>
                </div>
                <div className={cx("modal-button-group")}>
                    <button className={cx("cancel-button", "outline-gray-button")} onClick={() => onCloseModal()}>취소</button>
                    <button className={cx("confirm-button", "error-button")} onClick={() => handleSubmit()}>회원탈퇴</button>
                </div>
            </div>
        </div>
    );
};

export default DeleteAccountModal;