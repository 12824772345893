import React, { useEffect } from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getLetterDetail } from '../modules/slice';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { toHtml } from '../../utils/stringUtils';
import { defaultImage, handleImageError } from '../../utils/imageUtils';
import '../styles/LetterDescription.scss';

const cx = classNames.bind(styles);

const LetterDetail = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { detail } = useSelector(state => state.letter);

    const curr = moment();
    const isActive = moment(detail.start_date, 'YYYY-MM-DD').isBefore(curr) && moment(detail.end_date, 'YYYY-MM-DD').isAfter(curr);

    const { id } = useParams();

    useEffect(() => {
        dispatch(getLetterDetail({id}));
    }, [dispatch]);

    const handleBack = () => {
        navigate("/letter");
    }

    return (
        <div className={cx("letter-detail-view")}>
            <h2 className={cx("letter-detail-title")}>{detail.title}</h2>
            <div className={cx("letter-detail-content")}>
                <img className={cx("letter-detail-image")} onError={handleImageError} src={detail.thumb_image ?? defaultImage} alt={detail.title} />
                <div className={'letter-detail-desc'} dangerouslySetInnerHTML={{__html: toHtml(detail.description)}} />
            </div>
            <div className={cx("letter-detail-buttons")}>
                <button className={cx("outline-gray-button", "letter-detail-button")} onClick={() => handleBack()}>목록으로</button>
            </div>
        </div>
    );
};

export default LetterDetail;