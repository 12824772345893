import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "../../utils/apiUtils";

export const GET_EVENTS = "event/GET_EVENTS";
export const GET_EVENT_DETAIL = "event/GET_EVENT_DETAIL";

export const getEvents = createAsyncThunk(
    GET_EVENTS,
    async ({pageNum}) => {

        const data = {page: pageNum}

        return getApi('/api/events', data);
    },
);

export const getEventDetail = createAsyncThunk(
    GET_EVENT_DETAIL,
    async ({id}) => {

        return getApi(`/api/events/${id}`);
    },
);

const eventSlice = createSlice({
    name: 'event',
    initialState: {
        errorMessage: '',
        events: [],
        maxPage: 0,
        eventInitialPage: 1,
        detail: '',
    },
    reducers: {
        initEvent(state, action) {
            state.eventInitialPage = 1;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getEvents.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                state.events = [];
                state.maxPage = 0;
                state.eventInitialPage = 1;
            }
            else {
                state.errorMessage = '';
                state.events = action.payload.result;
                state.maxPage = action.payload.total_page;
                state.eventInitialPage = action.meta.arg.pageNum;
            }
        })

        .addCase(getEventDetail.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.detail = action.payload;
            }
        })
    }
});

export const { initEvent } = eventSlice.actions;
export default eventSlice.reducer;