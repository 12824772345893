import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { closeAllModal, closeLoginModal } from '../modules/slice';
import { useNavigate } from 'react-router-dom';
import { closeDetailModal } from '../../locate/modules/slice';

const cx = classNames.bind(styles);

const LoginModal = (props) => {

    const { zIndex = 300 } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isOpenLoginModal } = useSelector(state => state.ui);

    const handleCloseModal = () => {
        dispatch(closeLoginModal());
    }

    const handleLogin = () => {
        dispatch(closeAllModal());
        dispatch(closeDetailModal());
        navigate("/login");
    }

    return (
        isOpenLoginModal && <div className={cx("modal-background")}>
            <div className={cx("modal-background-color", isOpenLoginModal === true && "blocked")} style={{zIndex: zIndex && zIndex}} onClick={() => handleCloseModal()} />
            <div className={cx("modal-panel")} style={{zIndex: zIndex && zIndex+1}}>
                <h3 className={cx("modal-title")}>로그인이 필요합니다.</h3>
                <p className={cx("modal-desc")}>로그인 하시겠습니까?</p>
                <div className={cx("modal-button-group")}>
                    <button className={cx("outline-gray-button", "modal-button")} onClick={() => handleCloseModal()}>취소</button>
                    <button className={cx("primary-button", "modal-button")} onClick={() => handleLogin()}>로그인 하기</button>
                </div>
            </div>
        </div>
    );
}

export default LoginModal;