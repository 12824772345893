import React from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const DetergentItem = (props) => {

    const { imgSrc, itemName, count } = props;

    return (
        <>
            {count > 0 && <div className={cx("detergent-item")}>
                {imgSrc ? <img
                    onError={handleImageError}
                    src={imgSrc ?? defaultImage}
                    alt={itemName}
                    width={16}
                    height={16}
                /> : ''}
                <div className={cx("detergent-item-text")}>{itemName}</div>
                <div className={cx("detergent-item-count")}>{count}</div>
            </div>}
        </>
    );
};

export default DetergentItem;