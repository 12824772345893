import React from 'react';
import Layout from '../../layout/components/Layout';
import Introduction from '../components/Introduction';

const IntroductionPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Introduction />
        </Layout>
    );
};

export default IntroductionPage;