import React from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import NoticeListItem from './NoticeListItem';
import { Link, useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

const NoticeBanner = (props) => {

    const { notices } = props;
    const navigate = useNavigate();

    const handleClickNoticeButton = () => {
        navigate('/notice');
    }

    return (
        <div className={cx("home-banner", "background-banner")}>
            <Link to="/notice" className={cx("home-banner-title")}>그린필박스 새로운 소식</Link>
            <div className={cx("notice-list")}>
                {!!notices ? notices.map((notice) => <NoticeListItem key={notice.id} notice={notice} />) : ''}
            </div>
            <div className={cx("notice-banner-button-field")}>
                <button className={cx("outline-gray-button", "notice-banner-button")} onClick={handleClickNoticeButton}>자세히 보기</button>
            </div>
        </div>
    );
};

export default NoticeBanner;