import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { deleteApi, getApi, postApi, putApi } from "../../utils/apiUtils";
import { DATETIME_FORMAT } from "../../utils/constants";

export const REQUEST_VERIFY = "modifyUserInfo/REQUEST_VERIFY"; // 번호인증요청
export const VERIFY_PHONE = "modifyUserInfo/VERIFY_PHONE"; // 번호인증
export const CHANGE_PASSWORD = "modifyUserInfo/CHANGE_PASSWORD";
export const MODIFY_USER_INFO = "modifyUserInfo/MODIFY_USER_INFO";
export const DELETE_ACCOUNT = "modifyUserInfo/DELETE_ACCOUNT";

export const requestVerificationForModifyPhone = createAsyncThunk(
    REQUEST_VERIFY,
    async ({phoneNumber}) => {

        const data = {phone: phoneNumber};
        
        return getApi('/api/users/me/request-change-sms', data);
    },
);

export const verifyForModifyPhone = createAsyncThunk(
    VERIFY_PHONE,
    async ({phoneNumber, verificationCode}) => {

        const data = {phone: phoneNumber, verification_code: verificationCode};

        return postApi('/api/users/me/verify-change-phone-number', data);
    },
);

export const changePassword = createAsyncThunk(
    CHANGE_PASSWORD,
    async ({currentPassword, password}) => {

        const data = {
            current_password: currentPassword,
            new_password: password
        };

        return putApi('/api/users/me/change-password', data);
    },
);

export const modifyUserInfo = createAsyncThunk(
    MODIFY_USER_INFO,
    async ({name, email, birthDate, emailAgreement, smsAgreement}) => {

        const data = {
            name,
            email,
            birth_date: birthDate,
            email_opt_in: emailAgreement,
            sms_opt_in: smsAgreement,
        };

        return putApi('/api/users/me', data);
    },
);

export const deleteAccount = createAsyncThunk(
    DELETE_ACCOUNT,
    async () => {

        const data = {};

        return deleteApi('/api/users/me', data);
    },
);

const modifyUserInfoSlice = createSlice({
    name: 'modifyUserInfo',
    initialState: {
        errorMessage: '',
        verifyState: 'none',
        requestTime: '',
    },
    reducers: {
        initModifyPhone(state, action) {
            state.verifyState = 'none';
            state.requestTime = '';
            state.errorMessage = '';
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(requestVerificationForModifyPhone.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.verifyState = 'verifying';
                state.requestTime =  moment().format(DATETIME_FORMAT);
            }
        })

        .addCase(verifyForModifyPhone.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.verifyState = 'verified';
                state.requestTime = '';
            }
        })

        .addCase(modifyUserInfo.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                state.requestTime = '';
            }
            else {
                state.errorMessage = '';
                state.verifyState = 'registered';
            }
        })

        .addCase(deleteAccount.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                localStorage.setItem("accessToken", "");
            }
        })
    }
});

export const { initModifyPhone } = modifyUserInfoSlice.actions;
export default modifyUserInfoSlice.reducer;