import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { closeErrorModal } from '../modules/slice';

const cx = classNames.bind(styles);

const ErrorModal = (props) => {

    const { zIndex = 400 } = props;

    const dispatch = useDispatch();

    const { isOpenErrorModal, errorMessage } = useSelector(state => state.ui);

    const handleCloseModal = () => {
        dispatch(closeErrorModal());
    }

    return (
        isOpenErrorModal && <div className={cx("modal-background")}>
            <div className={cx("modal-background-color", isOpenErrorModal === true && "blocked")} style={{zIndex: zIndex && zIndex}} onClick={() => handleCloseModal()} />
            <div className={cx("modal-panel")} style={{zIndex: zIndex && zIndex+1}}>
                <h3 className={cx("modal-title")}>{errorMessage}</h3>
                <div className={cx("modal-button-group")}>
                    <button className={cx("primary-button", "modal-one-button")} onClick={() => handleCloseModal()}>확인</button>
                </div>
            </div>
        </div>
    );
}

export default ErrorModal;