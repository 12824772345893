import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteApi, getApi, postApi, putApi } from "../../utils/apiUtils";

export const GET_REVIEWS = "review/GET_REVIEWS";
export const GET_REVIEW_DETAIL = "review/GET_REVIEW_DETAIL";
export const WRITE_REVIEW = "review/WRITE_REVIEW";
export const UPDATE_REVIEW = "review/UPDATE_REVIEW";
export const DELETE_REVIEW = "review/DELETE_REVIEW";

export const getReviews = createAsyncThunk(
    GET_REVIEWS,
    async ({pageNum}) => {

        const data = {page: pageNum}

        return getApi('/api/reviews', data);
    },
);

export const getReviewDetail = createAsyncThunk(
    GET_REVIEW_DETAIL,
    async ({reviewId}) => {

        return getApi(`/api/reviews/${reviewId}`);
    },
);

export const writeReview = createAsyncThunk(
    WRITE_REVIEW,
    async ({paymentId, reviewContent, imageUrl}) => {

        const data = {
            payment_id: paymentId,
            content: reviewContent,
            review_image_url: imageUrl,
        };

        return postApi(`/api/reviews`, data);
    },
);

export const updateReview = createAsyncThunk(
    UPDATE_REVIEW,
    async ({reviewId, reviewContent, imageUrl, callback}) => {

        const data = {
            content: reviewContent,
            review_image_url: imageUrl,
        };

        return putApi(`/api/reviews/${reviewId}`, data, null, callback);
    },
);

export const deleteReview = createAsyncThunk(
    DELETE_REVIEW,
    async ({reviewId, callback}) => {

        const data = {};

        return deleteApi(`/api/reviews/${reviewId}`, data, null, callback);
    },
);

const reviewSlice = createSlice({
    name: 'review',
    initialState: {
        errorMessage: '',
        reviews: [],
        maxPage: 0,
        reviewInitialPage: 1,
        detail: '',
        updated: false,
    },
    reducers: {
        initReviews(state, action) {
            state.updated = false;
            state.reviewInitialPage = 1;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getReviews.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                state.events = [];
                state.maxPage = 0;
                state.reviewInitialPage = 1;
            }
            else {
                state.errorMessage = '';
                state.reviews = action.payload.result;
                state.maxPage = action.payload.total_page;
                state.updated = false;
                state.reviewInitialPage = action.meta.arg.pageNum;
            }
        })

        .addCase(getReviewDetail.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.detail = action.payload;
            }
        })

        .addCase(writeReview.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.updated = true;
            }
        })

        .addCase(updateReview.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.updated = true;
            }
        })

        .addCase(deleteReview.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.updated = true;
                state.reviewInitialPage = 1;
            }
        })
    }
});

export const { initReviews } = reviewSlice.actions;
export default reviewSlice.reducer;