import React from 'react';
import Header from '../../layout/components/Header';
import Locate from '../components/Locate';
import LoginModal from '../../ui/components/LoginModal';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import ErrorModal from '../../ui/components/ErrorModal';

const cx = classNames.bind(styles);

const LocatePage = () => {

    return (
        <>
            <Header pulldownTitle="그린필박스 위치" />
            <div className={cx('locate-main')}>
                <Locate />
            </div>
            <LoginModal />
            <ErrorModal />
        </>
    );
};

export default LocatePage;