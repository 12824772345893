import React from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const EventListItem = (props) => {

    const { event } = props;

    const curr = moment();
    const isActive = moment(event.start_date, 'YYYY-MM-DD').isBefore(curr) && moment(event.end_date, 'YYYY-MM-DD').isAfter(curr);

    return (
        <Link to={`/event/${event.id}`} className={cx("event-list-item")}>
            <div className={cx("event-list-item-image-view")}>
                <img className={cx("event-list-item-image")} src={event.thumb_image_url} onError={handleImageError} alt={event.title ?? defaultImage} width={360} height={360} />
                {!isActive ? <div className={cx("dimmed")} /> : ''}
                {isActive ? <div className={cx("event-list-item-progress", "processing")}>진행중</div> : <div className={cx("event-list-item-progress", "finished")}>이벤트 종료</div>}
                <div className={cx("event-list-item-image-view-border")}></div>
            </div>
            <div className={cx("event-list-item-title")}>{event.title}</div>
            <div className={cx("event-list-item-date")}>{moment(event.start_date, 'YYYY-MM-DD').format('YYYY.MM.DD')}~{moment(event.end_date, 'YYYY-MM-DD').format('YYYY.MM.DD')}</div>
        </Link>
    );
};

export default EventListItem;