import React, { useEffect, useMemo, useState } from 'react';
import { useTable } from "react-table";
import PageNumbers from '../../ui/components/PageNumbers';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getNoticeList, getTopNoticeList } from '../modules/slice';

const cx = classNames.bind(styles);

const NoticeBoardTable = () => {

    const { topList, list, maxPage, noticeInitialPage } = useSelector(state => state.notice);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [currPage, setCurrPage] = useState(1);
    const [isInitial, setIsInitial] = useState(true);

    useEffect(() => {
        if (isInitial) {
            setCurrPage(noticeInitialPage);
            dispatch(getTopNoticeList());
            dispatch(getNoticeList({pageNum: noticeInitialPage}));
            setIsInitial(false);
        }
    }, [noticeInitialPage]);

    const columns = useMemo(() => [
        {
            accessor: 'id',
            width: 0,
            Cell: ({ value, row }) => (<></>),
        },
        {
            accessor: 'always_on_top',
            Header: 'NoticeIcon',
            width: 62,
            Cell: ({ value, row }) => (
                <div className={cx("notice-pinned-cell")}>
                    {value === true ? <img className={cx("notice-pinned-image")} src="icons/announce.svg" width={24} height={24} /> : <></>}
                </div>
            ),
        },
        {
            accessor: 'title',
            Header: 'Title',
            width: 'auto',
            Cell: ({ value, row }) => (
                <div className={cx("notice-title-cell", row.values.always_on_top ? "bold" : "")}>{value}</div>
            ),
        },
        {
            accessor: 'start_date',
            Header: 'Date',
            width: 96,
            Cell: ({ value, row }) => (
                <div className={cx("notice-date-cell")}>{!!value ? moment(value, "YYYY-MM-DD HH:mm:ss").format("YYYY.MM.DD") : ''}</div>
            ),
        },
    ], []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: topList.concat(list) });

    const handleOnNoticeTableClick = (id) => {
        navigate(`/notice/${id}`);
    }

    const handleChangePage = (page) => {
        setCurrPage(page);
        dispatch(getNoticeList({pageNum: page}));
    }

    return (
        <>
            <table className={cx("notice-board-table")} {...getTableProps()}>
                <thead>
                    {headerGroups.map(header => (
                        <tr {...header.getHeaderGroupProps()}>
                            {header.headers.map(col => (
                            <th {...col.getHeaderProps()} style={{width: col.width}}></th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className={cx(row.values.always_on_top ? "on-top" : "")} onClick={() => handleOnNoticeTableClick(row.values.id)}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <PageNumbers currPage={currPage} maxPage={maxPage} onChangePage={handleChangePage} />
        </>
    );
};

export default NoticeBoardTable;