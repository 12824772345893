import React, { Suspense, useState, useEffect, useLayoutEffect } from 'react';
import { Container as MapDiv, NaverMap, useNavermaps, Marker, useMap, Overlay } from 'react-naver-maps';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { initRefreshMap, openDetailModal } from '../modules/slice';
import { searchGreenfill } from '../modules/slice';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH } from '../../utils/constants';

const cx = classNames.bind(styles);

const SearchMap = () => {

    const dispatch = useDispatch();

    const navermaps = useNavermaps();
    const {state} = useLocation();

    const { laundryFilter, fabricFilter, dishFilter, brandFilter, searchResults, refreshMap } = useSelector(state => state.locate);

    const [map, setMap] = useState(null);
    const [init, setInit] = useState(false);
    const [showResearchButton, setShowResearchButton] = useState(false);
    const [lastSearchBounds, setLastSearchBounds] = useState(null);
    const [initialState, setInitialState] = useState(true);

    const defaultCenter = new navermaps.LatLng(37.5666805, 126.9784147);
    const defaultZoom = 15;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

    useEffect(() => {
        if (refreshMap) {
            if (!!map && !!searchResults) {
              let avgPos = {lat: 0, lng: 0};
              if (searchResults.length > 0) {
                const sum = searchResults.map((res) => {
                  return {lat: res.gps_y, lng: res.gps_x};
                }).reduce((acc, current) => {
                  return {lng: Number(acc.lng) + Number(current.lng), lat: Number(acc.lat) + Number(current.lat)};
                }, avgPos);
                avgPos = {lat: sum.lat / searchResults.length, lng: sum.lng / searchResults.length};
              }
              const location = new navermaps.LatLng(
                avgPos.lat,
                avgPos.lng,
              );
              map.setCenter(location);
              dispatch(initRefreshMap());
            }
        }
    }, [refreshMap]);

    useEffect(() => {
        if (!!map) {
            if (initialState && !!state?.searchValue) {
                setInitialState(false);
                return;
            }
            const bounds = map.getBounds();
            searchOnBounds(bounds);
        }
    }, [laundryFilter, fabricFilter, dishFilter, brandFilter]);

    function searchOnBounds(bounds) {
        if (!bounds) return;
        dispatch(searchGreenfill({
          laundryFilter,
          fabricFilter,
          dishFilter,
          brandFilter,
          searchValue: '',
          tlx: bounds.getNE().lng(),
          tly: bounds.getNE().lat(),
          brx: bounds.getSW().lng(),
          bry: bounds.getSW().lat(),
        }));

        setLastSearchBounds(bounds);
        setShowResearchButton(false);
    }

    function onSuccessGeolocation(position) {
        if (!map) return

        const location = new navermaps.LatLng(
            position.coords.latitude,
            position.coords.longitude,
        )
        map.setCenter(location)
        map.setZoom(defaultZoom)

        const bounds = map.getBounds();
        searchOnBounds(bounds);
    }

    function onErrorGeolocation() {
        if (!map) return

        const bounds = map.getBounds();
        searchOnBounds(bounds);
    }

    useLayoutEffect(() => {
        if (!map || (!!state && !!state.searchValue)) {
            return
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                onSuccessGeolocation,
                onErrorGeolocation,
            );
        } else {
            const bounds = map.getBounds();
            searchOnBounds(bounds);

        }
    }, [map]);

    const handleMarkerClick = (storeId) => {
      dispatch(openDetailModal(storeId));
    }

    const renderResults = () => {
        return searchResults.map((item) => {

          const markerPos = new navermaps.LatLng(item.gps_y, item.gps_x);

          return <Marker
              title={item.title}
              icon={{
                content: 
                    item.store_favorite ? `<div class=${cx("marker-box")}>
                    <div class=${cx("marker-title")}>${item.title}</div>
                    <img class=${cx("marker-image")} src="icons/pin-favorite.svg" />
                    </div>` : `<div class=${cx("marker-box")}>
                    <div class=${cx("marker-title")}>${item.title}</div>
                    <img class=${cx("marker-image")} src="icons/pin-normal.svg" />
                    </div>`,
                size: new navermaps.Size(0, !isMobile ? 83 : 56),
                anchor: new navermaps.Point(0, !isMobile ? 83 : 56),
              }}
              position={markerPos}
              onClick={() => handleMarkerClick(item.id)}
          />;
        });
    }

    return (
        <MapDiv fallback={<></>} className={cx("search-map-view")}>
            <NaverMap
                defaultCenter={defaultCenter}
                defaultZoom={defaultZoom}
                defaultMapTypeId={navermaps.MapTypeId.NORMAL}
                ref={setMap}
                minZoom={10}
                onInit={() => {setInit(true)}}
                zoomControl={false}
                onBoundsChanged={(bounds) => {
                  window.setTimeout(function () {
                    if (!bounds.equals(lastSearchBounds)) {
                      setShowResearchButton(true);
                    }
                  }, 500);
                }}
            >
                {renderResults()}
                {init && !isMobile && <ZoomInButton />}
                {init && !isMobile && <ZoomOutButton />}
                {init && <MyLocationButton searchOnBounds={(bounds) => searchOnBounds(bounds)}/>}
                {init && showResearchButton && <ResearchButton searchOnBounds={(bounds) => searchOnBounds(bounds)}/>}
            </NaverMap>
        </MapDiv>
    );
};

const ZoomInButton = () => {
    const navermaps = useNavermaps();
    const map = useMap();

    const [zoomInControl] = useState(() => {
        return new navermaps.CustomControl(`
        <a href="#"
          style="
            z-index: 100;
            overflow: hidden;
            display: inline-block;
            position: absolute;
            top: 32px;
            right: 40px;
            width: 44px;
            height: 44px;
            border: 1px solid var(--gray-300-color);
            border-radius: 8px 8px 0 0;
            background: var(--white-color);
            text-align: center;
          "
        >
          <div style="margin: 10px;"><img src="icons/plus.svg" /></div>
        </a>
      `, {position: navermaps.Position.TOP_RIGHT})
    });

    useEffect(() => {
      // naver.maps.Event.addDOMListener 사용할 필요 없이, native addEventListener를 사용합니다.
      // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
      const domElement = zoomInControl.getElement()
      const domListener = () => {
        map.setZoom(map.getZoom() + 1, 500);
      }
  
      domElement.addEventListener('click', domListener)
  
      return () => {
        domElement.removeEventListener('click', domListener)
      }
    }, [])

    return <Overlay element={zoomInControl} />
}

const ZoomOutButton = () => {
  const navermaps = useNavermaps();
  const map = useMap();

  const [zoomOutControl] = useState(() => {
      return new navermaps.CustomControl(`
      <a href="#" 
        style="
          z-index: 100;
          overflow: hidden;
          display: inline-block;
          position: absolute;
          top: 76px;
          right: 40px;
          width: 44px;
          height: 44px;
          border: 1px solid var(--gray-300-color);
          border-radius: 0 0 8px 8px;
          background: var(--white-color);
          text-align: center;
        "
      >
        <div style="margin: 10px;"><img src="icons/minus.svg" /></div>
      </a>
    `, {position: navermaps.Position.TOP_RIGHT})
  });

  useEffect(() => {
    // naver.maps.Event.addDOMListener 사용할 필요 없이, native addEventListener를 사용합니다.
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
    const domElement = zoomOutControl.getElement()
    const domListener = () => {
      map.setZoom(map.getZoom() - 1, 500);
    }

    domElement.addEventListener('click', domListener)

    return () => {
      domElement.removeEventListener('click', domListener)
    }
  }, [])

  return <Overlay element={zoomOutControl} />
}

const MyLocationButton = (props) => {
  const { searchOnBounds } = props;
  const navermaps = useNavermaps();
  const map = useMap();

  const defaultCenter = new navermaps.LatLng(37.5666805, 126.9784147);
  const defaultZoom = 15;
  const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

  const [myLocationControl] = useState(() => {
      return new navermaps.CustomControl(!isMobile ? `
      <a href="#" 
        style="
          z-index: 100;
          overflow: hidden;
          display: inline-block;
          position: absolute;
          top: 132px;
          right: 40px;
          width: 44px;
          height: 44px;
          border: 1px solid var(--gray-300-color);
          border-radius: 8px;
          background: var(--white-color);
          text-align: center;
        "
      >
        <div style="margin: 10px;"><img src="icons/location.svg" /></div>
      </a>` : `
      <a href="#" 
        style="
          z-index: 100;
          overflow: hidden;
          display: inline-block;
          position: absolute;
          right: 16px;
          bottom: 32px;
          width: 36px;
          height: 36px;
          border: 1px solid var(--gray-300-color);
          border-radius: 8px;
          background: var(--white-color);
          text-align: center;
          box-shadow: 0 4px 8px 0 rgb(0, 0, 0, 0.12); 
        "
      >
        <div style="margin: 6px;"><img src="icons/location.svg" /></div>
      </a>`, {position: !isMobile ? navermaps.Position.TOP_RIGHT : navermaps.Position.BOTTOM_RIGHT})
  });

  function onSuccessGeolocation(position) {
      if (!map) return

      const location = new navermaps.LatLng(
          position.coords.latitude,
          position.coords.longitude,
      )
      map.setCenter(location)

      const bounds = map.getBounds();
      searchOnBounds(bounds);
  }

  function onErrorGeolocation() {
      if (!map) {
          return;
      }

      map.setZoom(defaultZoom);

      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
              onSuccessGeolocation,
              () => {},
          )
      } else {
      }
  }

  useEffect(() => {
    const domElement = myLocationControl.getElement()
    const domListener = () => {
      if (!map) {
          return
      }

      map.setZoom(defaultZoom);

      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
              onSuccessGeolocation,
              onErrorGeolocation,
          )
      } else {
          map.setCenter(defaultCenter);
          const bounds = map.getBounds();
          searchOnBounds(bounds);
      }
    }

    domElement.addEventListener('click', domListener)

    return () => {
      domElement.removeEventListener('click', domListener)
    }
  }, [])

  return <Overlay element={myLocationControl} />
}

const ResearchButton = (props) => {
  const { searchOnBounds } = props;
  const navermaps = useNavermaps();
  const map = useMap();
  const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

  const [researchControl] = useState(() => {
      return new navermaps.CustomControl(!isMobile ? `
      <a href="#" 
        style="
          z-index: 100;
          overflow: hidden;
          display: inline-block;
          position: absolute;
          top: 32px;
          left: -91px;
          width: 182px;
          height: 47px;
          border: 1px solid var(--primary-green-color);
          border-radius: 40px;
          background: var(--white-color);
          text-align: center;
          font-family: Pretendard Medium;
          font-weight: var(--medium-font-weight);
          line-height: var(--body-line-height);
          font-size: 18px;
          color: var(--green-70-color);
          text-decoration: none;
        "
      >
        <div style="
          margin: auto;
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          justify-content: center;
          height: 100%;">
          <img src="icons/refresh-green.svg" /><div>현 위치에서 검색</div></div>
      </a>` : `
      <a href="#" 
        style="
          z-index: 100;
          overflow: hidden;
          display: inline-block;
          position: absolute;
          top: 12px;
          left: -71.5px;
          width: 143px;
          height: 36px;
          border: 1px solid var(--primary-green-color);
          border-radius: 40px;
          background: var(--white-color);
          text-align: center;
          font-family: Pretendard Medium;
          font-weight: var(--medium-font-weight);
          line-height: var(--body-line-height);
          font-size: 12px;
          color: var(--green-70-color);
          text-decoration: none;
        "
      >
        <div style="
          margin: auto;
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          justify-content: center;
          height: 100%;">
          <img src="icons/refresh-green.svg" /><div>현 위치에서 검색</div></div>
      </a>`, {position: navermaps.Position.TOP_CENTER})
  });

  useEffect(() => {
    const domElement = researchControl.getElement()
    const domListener = () => {
        if (!map) {
            return
        }

        const bounds = map.getBounds();
        searchOnBounds(bounds);
    }

    domElement.addEventListener('click', domListener)

    return () => {
      domElement.removeEventListener('click', domListener)
    }
  }, [])

  return <Overlay element={researchControl} />
}

export default SearchMap;