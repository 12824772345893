import React, { Fragment } from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch } from 'react-redux';
import { getDateString } from '../../utils/datetimeUtils';
import { renderPrice } from '../../utils/stringUtils';
import { useNavigate } from 'react-router-dom';
import { deleteReview } from '../../reviews/modules/slice';
import { HttpStatusCode } from 'axios';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const MobilePurchaseDetergentTable = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { detergents, paymentId, reviewId, reviewable } = props;

    const handleWriteReview = (id) => {
        navigate(`/review/write/${id}`);
    }

    const handleUpdateReview = (reviewId) => {
        navigate(`/review/modify/${reviewId}`);
    }

    const handleDeleteReview = (reviewId) => {
        dispatch(deleteReview({reviewId}));
    }

    return (
        <ul className={cx("purchase-detergent-table")}>
            {!!detergents ? detergents.map((item) => {
                return <li className={cx("detergent-item")}>
                    <img className={cx("detergent-item-image")} onError={handleImageError} src={item?.product.thumb_image_url ?? defaultImage}/>
                    <div className={cx("detergent-item-content")}>
                        <div className={cx("detergent-item-title-line")}>
                            <span className={cx("detergent-item-title")}>{item.product.brand_name}</span>
                            <span className={cx("detergent-item-subtitle")}>{item.product.title}</span>
                        </div>
                        <div className={cx("detergent-item-desc-line")}>
                            <div className={cx("detergent-item-capacity")}>{renderPrice(item.product.item_capacity)}ml</div>
                            <div className={cx("vertical-divider")} />
                            <div className={cx("detergent-item-manufacture")}>{getDateString(item.manufacture_date, '-')} 제조</div>
                        </div>
                        <div className={cx("detergent-item-detail-line")}>
                            <div className={cx("detergent-item-detail-title")}>보틀</div>
                            <div className={cx("detergent-item-detail-desc", item.bottle_purchased ? 'active' : '')}>{item.bottle_purchased ? '구매' : '-'}</div>
                        </div>
                        <div className={cx("detergent-price")}>{renderPrice(item.product.price)}원</div>
                    </div>
                </li>;
            }) : ''}
        </ul>
    );
};

export default MobilePurchaseDetergentTable;