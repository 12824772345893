import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "../../utils/apiUtils";

export const GET_DETERGENT_LIST = "detergents/GET_DETERGENT_LIST";

export const getDetergentList = createAsyncThunk(
    GET_DETERGENT_LIST,
    async ({productType}) => {

        const data = {product_type: productType}

        return getApi('/api/products', data);
    },
);
const detergentSlice = createSlice({
    name: 'detergents',
    initialState: {
        errorMessage: '',
        detergents: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getDetergentList.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                state.detergents = [];
                state.maxPage = 0;
            }
            else {
                state.errorMessage = '';
                state.detergents = action.payload;
            }
        })
    }
});

export default detergentSlice.reducer;