import { useEffect, useState } from 'react';
import './App.module.scss';
import ErrorBoundary from './layout/components/ErrorBoundary';
import Router from './router';
import axios from 'axios';
import Loading from './Loading';
import { useNavermaps } from 'react-naver-maps';
import DetailModal from './locate/components/DetailModal';

function App() {

  const [loading, setLoading] = useState(false);
  useNavermaps();

  useEffect(() => {
  //axios 호출시 인터셉트
    axios.interceptors.request.use(function (config) {
      if(config.url!.includes('/api/')) {
        setLoading(true);
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    });
    //axios 호출 종료시 인터셉트
    axios.interceptors.response.use(function (response) {
      setLoading(false);
      return response;
    },
    function (error) {
      setLoading(false);
      return Promise.reject(error);
    });

  }, []);

  return (
    <>
      <ErrorBoundary>
        <Router />
        <DetailModal />
      </ErrorBoundary>
      <Loading loading={loading} />
    </>
  );
}

export default App;