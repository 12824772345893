import React, { useEffect, useRef, useState } from 'react';
import SearchItem from './SearchItem';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import FilterItem from './FilterItem';
import MultiFilterItem from './MultiFilterItem';
import { useDispatch, useSelector } from 'react-redux';
import { changeFilterValue, getBrandFilter, searchGreenfill } from '../modules/slice';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH } from '../../utils/constants';
import { useDrag } from 'react-use-gesture';

const cx = classNames.bind(styles);

const SearchList = () => {

    const dispatch = useDispatch();
    const {state} = useLocation();

    const { laundryFilter, fabricFilter, dishFilter, brandFilter, searchResults } = useSelector(state => state.locate);
    const [searchValue, setSearchValue] = useState('');
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });
    const [scrollUp, setScrollUp] = useState(false);
    const listRef = useRef(null);

    useEffect(() => {
        dispatch(getBrandFilter());
    }, []);

    useEffect(() => {
        if (!!state && !!state.searchValue) {
            setSearchValue(state.searchValue);
            dispatch(searchGreenfill({laundryFilter, fabricFilter, dishFilter, brandFilter, searchValue: state.searchValue}));
        }
    }, [state]);

    useEffect(() => {

        const handleOutside = (e) => {
            if (scrollUp && listRef.current && !listRef.current.contains(e.target)) {
                e.stopPropagation();
                setScrollUp(false);
            }
        };

        if (scrollUp) {
            window.addEventListener('mousedown', handleOutside);
        }
        
        return () => window.removeEventListener('mousedown', handleOutside);
    }, [scrollUp]);

    const handleScroll = useDrag((params) => {
        if (params.direction[1] < 0) {
            setScrollUp(true);
        }
    });

    const handleChangeSearchValue = e => {
        setSearchValue(e.target.value);
    }

    const onKeyDown = e => {
        if (e.key === 'Enter') {
            dispatch(searchGreenfill({laundryFilter, fabricFilter, dishFilter, brandFilter, searchValue}));
        }
    }

    const handleChangeLaundryFilter = (value) => {
        dispatch(changeFilterValue({filterName: "laundry", value}));
    }

    const handleChangeFabricFilter = (value) => {
        dispatch(changeFilterValue({filterName: "fabric", value}));
    }

    const handleChangeDishFilter = (value) => {
        dispatch(changeFilterValue({filterName: "dish", value}));
    }

    const handleChangeBrandFilter = (idx, value) => {
        dispatch(changeFilterValue({filterName: "brand", brandIndex: idx, value}));
    }

    return (
        <>
            {!isMobile ? <div className={cx("search-list-view")}>
                <h1 className={cx("search-list-title")}>그린필박스 위치</h1>
                <div className={cx("search-input-field")}>
                    <input className={cx("search-input")}
                        name="searchValue"
                        value={searchValue}
                        placeholder='매장명 또는 주소를 입력하세요.'
                        onChange={handleChangeSearchValue}
                        onKeyDown={onKeyDown}
                    />
                    <img
                        className={cx("search-icon")}
                        alt="search"
                        src="icons/search.svg"
                    />
                </div>
                <div className={cx("filter-list-view")}>
                    <FilterItem
                        imgSrc="icons/laundry-detergent.svg"
                        filterName="세탁세제"
                        isSelected={laundryFilter}
                        setSelected={handleChangeLaundryFilter}
                    />
                    <FilterItem
                        imgSrc="icons/fabric-softener.svg"
                        filterName="섬유유연제"
                        isSelected={fabricFilter}
                        setSelected={handleChangeFabricFilter}
                    />
                    <FilterItem
                        imgSrc="icons/dish-detergent.svg"
                        filterName="주방세제"
                        isSelected={dishFilter}
                        setSelected={handleChangeDishFilter}
                    />
                    <MultiFilterItem
                        filterName="브랜드"
                        filters={brandFilter}
                        setFilters={handleChangeBrandFilter}
                    />
                </div>
                <div className={cx("search-list-scroll")}>
                {
                    searchResults.length > 0 ? searchResults.map((result) => {
                        return (
                            <SearchItem item={result}/>
                        );
                    }) : <div className={cx("search-list-empty")}>
                        <div className={cx("search-list-empty-title")}>검색 결과가 없어요.</div>
                        <div className={cx("search-list-empty-desc")}>검색어 또는 필터를 확인해 주세요.</div>
                    </div>
                }
                </div>
            </div> : <>
                <div className={cx("search-view")}>
                    <div className={cx("search-input-field")}>
                        <input className={cx("search-input")}
                            name="searchValue"
                            value={searchValue}
                            placeholder='매장명 또는 주소를 입력하세요.'
                            onChange={handleChangeSearchValue}
                            onKeyDown={onKeyDown}
                        />
                        <img
                            className={cx("search-icon")}
                            alt="search"
                            src="icons/search.svg"
                        />
                    </div>
                </div>
                <div {...handleScroll()} ref={listRef} className={cx("search-list-view", scrollUp ? "scroll-up" : "")}>
                    <div className={cx("filter-list-view")}>
                        <FilterItem
                            imgSrc="icons/laundry-detergent.svg"
                            filterName="세탁세제"
                            isSelected={laundryFilter}
                            setSelected={handleChangeLaundryFilter}
                        />
                        <FilterItem
                            imgSrc="icons/fabric-softener.svg"
                            filterName="섬유유연제"
                            isSelected={fabricFilter}
                            setSelected={handleChangeFabricFilter}
                        />
                        <FilterItem
                            imgSrc="icons/dish-detergent.svg"
                            filterName="주방세제"
                            isSelected={dishFilter}
                            setSelected={handleChangeDishFilter}
                        />
                        <MultiFilterItem
                            filterName="브랜드"
                            filters={brandFilter}
                            setFilters={handleChangeBrandFilter}
                        />
                    </div>
                    {
                        searchResults.length > 0 ? <div className={cx("search-list-scroll", scrollUp ? "scroll-up" : "")}>
                            {searchResults.map((result) => {
                                return (
                                    <SearchItem item={result}/>
                                );
                            })}
                        </div> : <div className={cx("search-list-scroll")}>
                            <div className={cx("search-list-empty")}>
                                <div className={cx("search-list-empty-title")}>검색 결과가 없어요.</div>
                                <div className={cx("search-list-empty-desc")}>검색어 또는 필터를 확인해 주세요.</div>
                            </div>
                        </div>
                    }
                </div>
            </>}
        </>
    );
};

export default SearchList;