import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "../../utils/apiUtils";

export const GET_HOME_FEEDS = "home/GET_HOME_FEEDS";

export const getHomeFeeds = createAsyncThunk(
    GET_HOME_FEEDS,
    async () => {

        const data = {}

        return getApi('/api/homefeeds', data);
    },
);

const homeSlice = createSlice({
    name: 'home',
    initialState: {
        errorMessage: '',
        feeds: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getHomeFeeds.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                state.feeds = {};
            }
            else {
                state.errorMessage = '';
                state.feeds = action.payload;
            }
        })
    }
});

export default homeSlice.reducer;