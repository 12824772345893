import React from 'react';
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import ProgressBar from "@ramonak/react-progress-bar";
import StampCount from './StampCount';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH } from '../../utils/constants';
import MobileStampCount from './MobileStampCount';

const cx = classNames.bind(styles);

const StampBar = (props) => {

    const { stampCount } = props;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

    return (
        <div className={cx("stamp-bar-view")}>
            <div className={cx("stamp-bar-header")}>
                <div className={cx("stamp-bar-title")}>
                    리필 쿠폰까지
                    <div><span style={{color: "var(--primary-green-color)"}}>{stampCount}</span>/{30}</div>
                </div>
            </div>
            <div className={cx("stamp-bar-body")}>
                <ProgressBar
                    bgColor='var(--primary-green-color)'
                    baseBgColor='var(--gray-300-color)'
                    borderRadius='8px'
                    isLabelVisible={false}
                    completed={stampCount/30 * 100}
                />
                <StampCount stampCount={stampCount} count={0} />
                <StampCount stampCount={stampCount} count={10} />
                <StampCount stampCount={stampCount} count={15} />
                <StampCount stampCount={stampCount} count={20} />
                <StampCount stampCount={stampCount} count={25} />
                <StampCount stampCount={stampCount} count={30} />
                {isMobile && <ul className={cx("stamp-bar-count-list")}>
                    <MobileStampCount stampCount={stampCount} count={10} />
                    <MobileStampCount stampCount={stampCount} count={15} />
                    <MobileStampCount stampCount={stampCount} count={20} />
                    <MobileStampCount stampCount={stampCount} count={25} />
                    <MobileStampCount stampCount={stampCount} count={30} />
                </ul>}
            </div>
        </div>
    );
};

export default StampBar;