import React from 'react';
import NoticeBoardTable from './NoticeBoardTable';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH } from '../../utils/constants';
import MobileNoticeBoardTable from './MobileNoticeBoardTable';

const cx = classNames.bind(styles);

const NoticeBoard = () => {

    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

    return (
        <div className={cx("notice-board-view")}>
            {!isMobile ? <NoticeBoardTable /> : <MobileNoticeBoardTable />}
        </div>
    );
};

export default NoticeBoard;