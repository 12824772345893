import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initModifyPhone, requestVerificationForModifyPhone, verifyForModifyPhone } from '../modules/slice';
import { useInterval } from '../../utils/datetimeUtils';
import moment from 'moment';
import {ReactComponent as Cross} from '../../icons/cross.svg';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { DATETIME_FORMAT } from '../../utils/constants';
import { getUserInfo } from '../../myPage/modules/slice';

const cx = classNames.bind(styles);

const ModifyPhoneModal = (props) => {


    const { isOpen, onCloseModal } = props;
    const { verifyState, requestTime, errorMessage } = useSelector(state => state.modifyUserInfo);
    const dispatch = useDispatch();

    const [phoneNumber, setPhoneNumber] = useState('');
    const [verifyNumber, setVerifyNumber] = useState('');
    const [remainTime, setRemainTime] = useState('');


    const [errors, setErrors] = useState({
        verifyNumber: "",
    });

    useInterval(
        () => {
            if (!!requestTime) {
                const now = moment();
                const limitTime = moment(requestTime, DATETIME_FORMAT).add(3, 'minutes');
                const diff = moment.duration(limitTime.diff(now)).asMilliseconds();
                var diffStr = '';
                if (diff > 0) {
                    diffStr = moment(diff).format("m:ss");
                }
                setRemainTime(diffStr);
            }
        },
        1000,
    );
    
    useEffect(() => {
        if (isOpen) {
            dispatch(initModifyPhone());
            setPhoneNumber('');
            setVerifyNumber('');
            setRemainTime('');
        }
    }, [isOpen]);

    useEffect(() => {
        if (verifyState === 'verified') {
            dispatch(getUserInfo());
            onCloseModal();
        }
    }, [verifyState]);

    useEffect(() => {
        const errors = {
            verifyNumber: errorMessage,
        };
        setErrors(errors);
    }, [errorMessage]);

    const handleChangePhoneNumber = e => {

        const phone = e.target.value;

        setPhoneNumber(phone
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`));
    }

    const handleChangeVerifyNumber = e => {
        setVerifyNumber(e.target.value);
    }

    const requestVerification = () => {
        dispatch(requestVerificationForModifyPhone({phoneNumber: phoneNumber.replace(/[^0-9]/g, '')}));
    }

    const validate = () => {
        const errors = {
            verifyNumber: "",
        };
    
        if (!verifyNumber) {
            errors.verifyNumber = "인증번호를 입력하세요";
        }
    
        return errors;
    }

    const handleSubmit = e => {
        e.preventDefault()

        const errors = validate();
        // 에러 값을 설정하고
        setErrors(errors);
        // 잘못된 값이면 제출 처리를 중단한다.
        if (Object.values(errors).some(v => v)) {
          return;
        }

        dispatch(verifyForModifyPhone({
            phoneNumber: phoneNumber.replace(/[^0-9]/g, ''),
            verificationCode: verifyNumber,
        }));
    }

    return (
        <div className={cx("modal-background")}>
            <div className={cx("modal-background-color", "blocked")} onClick={() => onCloseModal()} />
            <div className={cx("modal-panel")}>
                <div className={cx("modal-headline")}>
                    <h1 className={cx("modal-title")}>아이디(휴대폰 번호) 수정</h1>
                    <button className={cx("modal-close")} onClick={() => onCloseModal()}>
                        <Cross
                            width={24}
                            height={24}
                        />
                    </button>
                </div>
                <p className={cx("modify-phone-desc")}>아이디(휴대폰 번호)를 수정하기 위해 인증절차가 필요합니다.</p>
                <div className={cx("list-item-full")}>
                    <div className={cx("request-verify-input-field")}>
                        <input className={cx("list-item-full")}
                            type="text"
                            name="phoneNumber"
                            value={phoneNumber}
                            placeholder='휴대폰 번호(-없이 숫자만 입력)'
                            onChange={handleChangePhoneNumber}
                            maxLength={13}
                        />
                        <button className={cx("outline-button", "request-verify-button")} onClick={requestVerification} type="button">인증요청</button>
                    </div>
                    {verifyState === 'verifying' ? <>
                        <div className={cx("list-item-full", "verify-code-input-field")}>
                            <input className={cx("verify-code-input")}
                                type="numeric"
                                name="verifyNumber"
                                value={verifyNumber}
                                placeholder='인증번호 입력'
                                onChange={handleChangeVerifyNumber}
                            />
                            {remainTime ? <div className={cx("count-down-text")}><span>{remainTime}</span></div> : ''}
                        </div>
                        {errors.verifyNumber && <div className={cx("input-error-text", "register-error-text")}>
                            <span>{errors.verifyNumber}</span>
                        </div>}
                        <div className={cx("resend-verify-request")} onClick={requestVerification}>
                            <div className={cx("resend-button", "text-link-button")}><span className={cx("text-link-button-inner")}>인증번호 재발송</span></div>
                        </div>
                    </> : <div className={cx("verify-empty-field")}>
                        {errors.verifyNumber && <div className={cx("input-error-text", "register-error-text")}>
                            <span>{errors.verifyNumber}</span>
                        </div>}
                    </div>}
                    <div className={cx("list-item-full")}>
                        <button disabled={verifyState !== 'verifying'} className={cx("list-item-full", "confirm-button", "primary-button")} onClick={handleSubmit}>인증 확인</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModifyPhoneModal;