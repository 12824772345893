import React from 'react';
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH } from '../../utils/constants';

const cx = classNames.bind(styles);

const StampCount = (props) => {

    const { stampCount, count } = props;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

    const getCouponCostLabel = (c) => {
        let costLabel = '';
        switch (c) {
            case 10: costLabel = '500원'; break;
            case 15: costLabel = '700원'; break;
            case 20: costLabel = '1,000원'; break;
            case 25: costLabel = '1,500원'; break;
            case 30: costLabel = '1회 무료'; break;
        }

        return costLabel;
    }

    return (
        <div className={cx("stamp-bar-count-view", `stamp-count-${count}`)}>
            <div className={cx("stamp-bar-count")}>
                {count}
            </div>
            {(count !== 0 && !isMobile) ? <div className={cx(stampCount >= count ? "stamp-bar-count-coupon-active" : "stamp-bar-count-coupon")}>
                {getCouponCostLabel(count)}
                {count !== 30 && <><br />할인 쿠폰</>}
            </div> : ''}
        </div>
    );
};

export default StampCount;