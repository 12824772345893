import React from 'react';
import Layout from '../../layout/components/Layout';
import Events from '../components/Events';
import Title from '../../layout/components/Title';

const EventListPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Title title="이벤트">
                <Events />
            </Title>
        </Layout>
    );
};

export default EventListPage;