import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "../../utils/apiUtils";

export const GET_USER_INFO = "myPage/GET_USER_INFO";

export const getUserInfo = createAsyncThunk(
    GET_USER_INFO,
    async () => {

        const data = {}

        return getApi('/api/users/me', data);
    },
);

const myPageSlice = createSlice({
    name: 'myPage',
    initialState: {
        errorMessage: '',
        myInfo: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getUserInfo.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                alert(action.payload.error.message);
                localStorage.setItem('accessToken', '');
            }
            else {
                state.errorMessage = '';
                state.myInfo = action.payload;
            }
        })
    }
});

export default myPageSlice.reducer;