import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "../../utils/apiUtils";

export const GET_FAQ_LIST = "questions/GET_FAQ_LIST";

export const getFaqList = createAsyncThunk(
    GET_FAQ_LIST,
    async ({pageNum}) => {

        const data = {page: pageNum};
        
        return getApi('/api/faqs', data);
    },
);

const questionSlice = createSlice({
    name: 'question',
    initialState: {
        errorMessage: '',
        list: [],
        maxPage: 0,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getFaqList.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.list = action.payload.result;
                state.maxPage = action.payload.total_page;
            }
        })
    }
});

export default questionSlice.reducer;