import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getApi, postApi } from "../../utils/apiUtils";
import { DATETIME_FORMAT } from "../../utils/constants";

export const REQUEST_VERIFY = "resetPassword/REQUEST_VERIFY" // 번호인증요청(3분제한)
export const VERIFY_PHONE = "resetPassword/VERIFY_PHONE"; // 번호인증
export const RESET_PASSWORD = "resetPassword/RESET_PASSWORD"; // 비밀번호 변경

export const requestVerificationForReset = createAsyncThunk(
    REQUEST_VERIFY,
    async ({phoneNumber}) => {

        const data = {phone: phoneNumber};

        return getApi('/api/signs/request-reset-password-sms', data);
    },
);

export const verifyForReset = createAsyncThunk(
    VERIFY_PHONE,
    async ({phoneNumber, verificationCode}) => {

        const data = {phone: phoneNumber, verification_code: verificationCode};

        return postApi('/api/signs/verify-reset-password-phone-number', data);
    },
);

export const resetPassword = createAsyncThunk(
    RESET_PASSWORD,
    async ({accessToken, password}) => {

        const data = {password};

        return postApi('/api/signs/reset-password', data, {
            "Content-Type": `application/json`,
            "X-Access-Token": accessToken,
        });
    },
);

const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState: {
        accessToken: '',
        errorMessage: '',
        verifyState: 'none',
        requestTime: '',
    },
    reducers: {
        initResetPassword(state, action) {
            state.verifyState = 'none';
            state.requestTime = '';
            state.errorMessage = '';
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(requestVerificationForReset.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                state.requestTime = '';
            }
            else {
                state.phoneNumber = action.meta.arg.phoneNumber;
                state.errorMessage = '';
                state.verifyState = 'verifying';
                state.requestTime =  moment().format(DATETIME_FORMAT);
            }
        })

        .addCase(verifyForReset.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.accessToken = action.payload.access_token;
                state.verifyState = 'verified';
                state.requestTime = '';
            }
        })

        .addCase(resetPassword.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
            }
            else {
                state.errorMessage = '';
                state.verifyState = 'complete';
            }
        })
    }
});

export const { initResetPassword } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;