import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "../../utils/apiUtils";

export const INIT_LOGIN = "login/INIT_LOGIN";
export const SIGN_IN = "login/SIGN_IN"; // 로그인

export const initLogin = createAsyncThunk(
    INIT_LOGIN,
    () => {}
);

export const signIn = createAsyncThunk(
    SIGN_IN,
    async ({phoneNumber, password}) => {

        const data = {phone: phoneNumber, password}

        return postApi('/api/signs/sign-in', data);
    },
);

const loginSlice = createSlice({
    name: 'login',
    initialState: {
        errorMessage: '',
        refresh: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(initLogin.fulfilled, (state, action) => {
            state.errorMessage = "";
            state.refresh = false;
        })
        .addCase(signIn.fulfilled, (state, action) => {
            if (!!action.payload.error) {
                state.errorMessage = action.payload.error.message;
                localStorage.setItem("accessToken", '');
                state.refresh = false;
            }
            else {
                state.errorMessage = '';
                localStorage.setItem("accessToken", action.payload.access_token);
                state.refresh = true;
            }
        })
    }
});

export default loginSlice.reducer;