import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import {ReactComponent as RightArrow} from '../../icons/arrow-right.svg';
import {ReactComponent as DownArrow} from '../../icons/pull-down.svg';
import { getUserInfo } from '../../myPage/modules/slice';
import ModifyPhoneModal from './ModifyPhoneModal';
import ChangePasswordModal from './ChangePasswordModal';
import DeleteAccountModal from './DeleteAccountModal';
import { modifyUserInfo } from '../modules/slice';
import { checkBirthDate } from '../../utils/datetimeUtils';

const cx = classNames.bind(styles);

const ModifyUserInfo = () => {

    const { myInfo } = useSelector(state => state.myPage);
    const dispatch = useDispatch();

    const [userName, setUserName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [emailAgreement, setEmailAgreement] = useState(false);
    const [smsAgreement, setSmsAgreement] = useState(false);

    const [isOpenModifyPhoneModal, setIsOpenModifyPhoneModal] = useState(false);
    const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] = useState(false);
    const [isOpenDeleteAccountModal, setIsOpenDeleteAccountModal] = useState(false);

    const [errors, setErrors] = useState({
        userName: "",
        emailAddress: "",
        birthDate: "",
    });

    useEffect(() => {
        dispatch(getUserInfo());
    }, []);

    useEffect(() => {
        setUserName(myInfo.name);
        setEmailAddress(myInfo.email);
        setBirthDate(myInfo.birth_date);
        setEmailAgreement(myInfo.email_opt_in);
        setSmsAgreement(myInfo.sms_opt_in);
    }, [myInfo]);

    const handleChangeUserName = e => {
        setUserName(e.target.value);
    }

    const handleChangeEmailAddress = e => {
        setEmailAddress(e.target.value);
    }

    const handleChangeBirthDate = e => {
        const date = e.target.value;

        setBirthDate(date
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{4})(\d{1,2})(\d{1,2})$/, `$1-$2-$3`));
    }

    const handleChangeEmailAgreement = check => {
        setEmailAgreement(check);
    }

    const handleChangeSmsAgreement = check => {
        setSmsAgreement(check);
    }

    const validate = () => {
        const errors = {
            userName: "",
            emailAddress: "",
            birthDate: "",
        };

        const emailRegex = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;

        if (!userName) {
            errors.userName = "필수 입력 항목입니다.";
        }

        if (!!emailAddress && !emailRegex.test(emailAddress)) {
            errors.emailAddress = "이메일 형식이 올바르지 않습니다.";
        }

        if (!!birthDate && !checkBirthDate(birthDate)) {
            errors.birthDate = "생년월일이 올바르지 않습니다.";
        }
    
        return errors;
    }

    const handleSubmit = e => {
        e.preventDefault()

        const errors = validate();
        // 에러 값을 설정하고
        setErrors(errors);
        // 잘못된 값이면 제출 처리를 중단한다.
        if (Object.values(errors).some(v => v)) {
          return;
        }

        dispatch(modifyUserInfo({name: userName, email: emailAddress, birthDate, emailAgreement, smsAgreement}));
    }

    return (
        <div className={cx("modify-user-view")}>
            <form className={cx("modify-user-form-field")} onSubmit={handleSubmit}>
                <div className={cx("modify-user-input-field")}>
                    <div className={cx("modify-user-input-field-title")}>아이디(휴대폰 번호) <span className={cx("input-required")}>*</span></div>
                    <div className={cx("modify-user-input-field-body")}>
                        <span className={cx("modify-user-input-field-desc")}>{myInfo.phone ? myInfo.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) : ''}</span>
                        <button type="button" className={cx("outline-gray-button", "modify-button")} onClick={() => setIsOpenModifyPhoneModal(true)}>번호인증으로 수정하기</button>
                    </div>
                </div>
                <div className={cx("modify-user-input-field")}>
                    <div className={cx("modify-user-input-field-title")}>
                        비밀번호 <span className={cx("input-required")}>*</span>
                    </div>
                    <button type="button" className={cx("outline-gray-button", "modify-button")} onClick={() => setIsOpenChangePasswordModal(true)}>변경하기</button>
                </div>
                <div className={cx("modify-user-input-field")}>
                    <div className={cx("modify-user-input-field-title")}>
                        이름 <span className={cx("input-required")}>*</span>
                    </div>
                    <div className={cx("modify-user-input-field-content")}>
                        <input className={cx("modify-user-input")}
                            name="userName"
                            value={userName}
                            placeholder='김그린'
                            onChange={handleChangeUserName}
                        />
                        {errors.userName && <div className={cx("input-error-text")}><span>{errors.userName}</span></div>}
                    </div>
                </div>
                <div className={cx("modify-user-input-field")}>
                    <div className={cx("modify-user-input-field-title")}>이메일</div>
                    <div className={cx("modify-user-input-field-content")}>
                        <input className={cx("modify-user-input")}
                            name="emailAddress"
                            value={emailAddress}
                            placeholder='greenfill@email.com'
                            onChange={handleChangeEmailAddress}
                        />
                        {errors.emailAddress && <div className={cx("input-error-text")}><span>{errors.emailAddress}</span></div>}
                    </div>
                </div>
                <div className={cx("modify-user-input-field", "last-field")}>
                    <div className={cx("modify-user-input-field-title")}>생년월일</div>
                    <div className={cx("modify-user-input-field-content")}>
                        <input className={cx("modify-user-input")}
                            name="birthDate"
                            value={birthDate}
                            placeholder='생년월일을 숫자만 입력해주세요.'
                            onChange={handleChangeBirthDate}
                            maxLength={10}
                        />
                        {errors.birthDate && <div className={cx("input-error-text")}><span>{errors.birthDate}</span></div>}
                        <div className={cx("input-tooltip-text")}>
                            <span>생년월일을 입력하면 <span className={cx("coupon-text")}>생일 쿠폰</span>을 드려요!</span>
                        </div>
                    </div>
                </div>
                <div className={cx("list-item-full", "modify-user-checkbox-input-field")}>
                    <div className={cx("checkbox-input-field")}>
                        <input id="emailAgreement"
                            className={cx("checkbox")}
                            type="checkbox"
                            checked={emailAgreement}
                            onChange={({ target: { checked } }) => handleChangeEmailAgreement(checked)}
                        />
                        <label htmlFor="emailAgreement" className={cx("checkbox-label")}>
                            <span>이메일 수신동의</span>
                        </label>
                    </div>
                    <a style={{height: "24px"}} target="_blank" href="https://cypress-curiosity-65a.notion.site/a20ec15b7f864bf1a9f886e259ebea76?pvs=4">
                        <RightArrow className={cx("arrow-right")} />
                    </a>
                </div>
                <div className={cx("list-item-full", "modify-user-checkbox-input-field")}>
                    <div className={cx("checkbox-input-field")}>
                        <input id="smsAgreement"
                            className={cx("checkbox")}
                            type="checkbox"
                            checked={smsAgreement}
                            onChange={({ target: { checked } }) => handleChangeSmsAgreement(checked)}
                        />
                        <label htmlFor="smsAgreement" className={cx("checkbox-label")}>
                            <span>SMS 수신동의</span>
                        </label>
                    </div>
                    <a style={{height: "24px"}} target="_blank" href="https://cypress-curiosity-65a.notion.site/a20ec15b7f864bf1a9f886e259ebea76?pvs=4">
                        <RightArrow className={cx("arrow-right")} />
                    </a>
                </div>
                <div className={cx("list-item-full")}>
                    <button className={cx("list-item-full", "primary-button", "confirm-button")} type="submit">수정 완료</button>
                </div>
            </form>
            <div className={cx("modify-user-footer-wrapper")}>
                <span className={cx("modify-user-footer-desc")}>그린필박스 회원탈퇴를 원하시면</span>
                <a href="#" className={cx("modify-user-footer-button")} onClick={() => setIsOpenDeleteAccountModal(true)}>회원탈퇴하기</a>
            </div>
            {isOpenModifyPhoneModal && <ModifyPhoneModal isOpen={isOpenModifyPhoneModal} onCloseModal={() => setIsOpenModifyPhoneModal(false)} />}
            {isOpenChangePasswordModal && <ChangePasswordModal isOpen={isOpenChangePasswordModal} onCloseModal={() => setIsOpenChangePasswordModal(false)} />}
            {isOpenDeleteAccountModal && <DeleteAccountModal isOpen={isOpenDeleteAccountModal} onCloseModal={() => setIsOpenDeleteAccountModal(false)} />}
        </div>
    );
};

export default ModifyUserInfo;