import React, { useEffect } from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentDetail } from '../modules/slice';
import { useParams } from 'react-router-dom';
import PurchaseDetergentTable from './PurchaseDetergentTable';
import { getDateString } from '../../utils/datetimeUtils';
import { renderPrice } from '../../utils/stringUtils';
import { initReviews } from '../../reviews/modules/slice';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH } from '../../utils/constants';
import MobilePurchaseDetergentTable from './MobilePurchaseDetergentTable';

const cx = classNames.bind(styles);

const PurchaseHistoryDetail = () => {

    const dispatch = useDispatch();
    const { updated } = useSelector(state => state.review);
    const { detail } = useSelector(state => state.payment);
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

    const { id } = useParams();

    useEffect(() => {
        dispatch(getPaymentDetail({id}));
    }, [dispatch]);

    useEffect(() => {
        if (updated) {
            dispatch(getPaymentDetail({id}));
            dispatch(initReviews());
        }
    }, [updated]);

    const renderPaymentMethod = () => {
        if (detail?.payment_method_value === 'KAKAO') {
            return '카카오페이';
        }
        else if (detail?.payment_method === 'CREDIT_CARD') {
            return detail?.payment_method_value;
        }
        else {
            return detail?.payment_method_value;
        }
    }

    return (
        <div className={cx("payment-detail-view")}>
            <dl className={cx("payment-detail-header")}>
                <dt className={cx("payment-detail-title")}>구매 일시</dt>
                <dd className={cx("payment-detail-desc")}>{getDateString(detail?.created_at, '-')}</dd>
                <dt className={cx("payment-detail-title")}>구매 ID</dt>
                <dd className={cx("payment-detail-desc")}>{detail?.id}</dd>
                <dt className={cx("payment-detail-title")}>구입한 매장</dt>
                <dd className={cx("payment-detail-desc")}>{detail?.store?.title}</dd>
                <dt className={cx("payment-detail-title")}>본사 정보</dt>
                <dd className={cx("payment-detail-desc")}>{'서울 용산구 한강대로 366(동자동)오피스동 608호'}</dd>
                <dt className={cx("payment-detail-title")}>전화번호</dt>
                <dd className={cx("payment-detail-desc")}>{'070-4667-4020'}</dd>
            </dl>
            <div className={cx("payment-detail-detergent-table-view")}>
                <h3 className={cx("payment-detail-subtitle")}>상품정보</h3>
                {!isMobile ?
                    <PurchaseDetergentTable detergents={detail?.payment_items} reviewId={detail?.review_id} reviewable={detail?.reviewable} paymentId={id} /> :
                    <MobilePurchaseDetergentTable detergents={detail?.payment_items} reviewId={detail?.review_id} reviewable={detail?.reviewable} paymentId={id} />
                }
            </div>
            <div className={cx("payment-detail-footer")}>
                <h3 className={cx("payment-detail-subtitle")}>결제정보</h3>
                <div className={cx("total-price-view")}>
                    <div className={cx("total-price-title")}>총 결제금액</div>
                    <div className={cx("total-price-desc")}>{detail?.final_amount}원</div>
                </div>
                <dl className={cx("payment-detail-cost-view")}>
                    <dt className={cx("payment-detail-cost-title")}>총 상품금액</dt>
                    <dd className={cx("payment-detail-cost-desc")}>{renderPrice(detail?.total_amount)}원</dd>
                    <dt className={cx("payment-detail-cost-title")}>쿠폰할인</dt>
                    <dd className={cx("payment-detail-cost-desc")}>{-renderPrice(detail?.discount_amount)}원</dd>
                    <dt className={cx("payment-detail-cost-title")}>결제 수단</dt>
                    <dd className={cx("payment-detail-cost-desc")}>{renderPaymentMethod()}</dd>
                </dl>
            </div>
        </div>
    );
};

export default PurchaseHistoryDetail;