import React from 'react';
import Layout from '../../layout/components/Layout';
import Title from '../../layout/components/Title';
import DetergentList from '../components/DetergentList';

const DetergentListPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true} pulldownTitle="그린필박스 세제 정보">
            <Title title={'그린필박스 세제 정보'}>
                <DetergentList />
            </Title>
        </Layout>
    );
};

export default DetergentListPage;