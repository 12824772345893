import AWS from 'aws-sdk';

AWS.config.region = 'ap-northeast-2';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_AWS_UPLOAD_POOL_ID,
});

var s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: 'grfimgbucket' },
});

export function upload(file, progressCallback, resultCallback) {

    var params = {
        Key: `${new Date().getTime()}.${file.name.split('.').slice(-1)}`,
        ContentType: file.type,
        Body: file,
        ACL: 'public-read',
    };
    s3.upload(params)
      .on('httpUploadProgress', function (evt) {
        progressCallback((evt.loaded * 100) / evt.total);
    })
    .send(function (err, data) {
        if (err) {
            throw new Error(err);
        }
        resultCallback(data);
    });
}

export const handleImageError = (e) => {
    e.target.src = defaultImage;
}

export const defaultImage = '/img/default-image.png';