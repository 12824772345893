import React, { useEffect } from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getNoticeDetail } from '../modules/slice';
import { useNavigate, useParams } from 'react-router-dom';
import { getDateString } from '../../utils/datetimeUtils';
import { toHtml } from '../../utils/stringUtils';

const cx = classNames.bind(styles);

const NoticeDetail = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { detail } = useSelector(state => state.notice);

    const { id } = useParams();

    useEffect(() => {
        dispatch(getNoticeDetail({id}));
    }, []);

    const handleBack = () => {
        navigate("/notice");
    }

    return (
        <div className={cx("notice-detail-view")}>
            <h2 className={cx("notice-detail-title")}>{detail.title}</h2>
            <div className={cx("notice-detail-create-date")}>{getDateString(detail.start_date)}</div>
            <div className={cx("notice-detail-content")} dangerouslySetInnerHTML={{__html: toHtml(detail.content)}} />
            <div className={cx("notice-detail-buttons")}>
                <button className={cx("outline-gray-button", "notice-detail-button")} onClick={() => handleBack()}>목록으로</button>
            </div>
        </div>
    );
};

export default NoticeDetail;