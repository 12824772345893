import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import PageNumbers from '../../ui/components/PageNumbers';
import { useDispatch, useSelector } from 'react-redux';
import { getLetters } from '../modules/slice';
import LetterListItem from './LetterListItem';

const cx = classNames.bind(styles);

const Letters = () => {

    const dispatch = useDispatch();

    const { letters, maxPage, letterInitialPage } = useSelector(state => state.letter);

    const [currPage, setCurrPage] = useState(1);
    const [isInitial, setIsInitial] = useState(true);

    useEffect(() => {
        if (isInitial) {
            setCurrPage(letterInitialPage);
            dispatch(getLetters({pageNum: letterInitialPage}));
            setIsInitial(false);
        }
    }, [letterInitialPage]);

    const handleChangePage = (page) => {
        setCurrPage(page);
        dispatch(getLetters({pageNum: page}));
    }

    return (
        <div className={cx("letter-view")}>
            <div className={cx("letter-list-view")}>
                {!!letters ? letters.map((letter) => {
                    return <LetterListItem key={letter.id} letter={letter} />;
                }) : ''}
            </div>
            <PageNumbers currPage={currPage} maxPage={maxPage} onChangePage={handleChangePage} />
        </div>
    );
};

export default Letters;