import React, { useState } from 'react';
import Layout from '../../layout/components/Layout';
import Title from '../../layout/components/Title';
import RegisterCoupon from '../components/RegisterCoupon';
import CouponList from '../components/CouponList';

const CouponListPage = () => {

    const [filterState, setFilterState] = useState('AVAILABLE');

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Title title="쿠폰">
                <RegisterCoupon setFilterState={setFilterState} />
                <CouponList filterState={filterState} setFilterState={setFilterState} />
            </Title>
        </Layout>
    );
};

export default CouponListPage;