import React from 'react';
import Layout from '../../layout/components/Layout';
import PurchaseHistory from '../components/PurchaseHistory';
import Title from '../../layout/components/Title';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MAX_WIDTH } from '../../utils/constants';
import MobilePurchaseHistory from '../components/MobilePurchaseHistory';

const PurchaseHistoryPage = () => {

    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Title title="구매내역">
                {!isMobile ? <PurchaseHistory /> : <MobilePurchaseHistory />}
            </Title>
        </Layout>
    );
};

export default PurchaseHistoryPage;