import React from 'react';
import Layout from '../../layout/components/Layout';
import Home from '../components/Home';

const HomePage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Home />
        </Layout>
    );
};

export default HomePage;