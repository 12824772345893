import React, { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {ReactComponent as RightArrow} from '../../icons/arrow-right-small.svg';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getDateString } from '../../utils/datetimeUtils';
import { renderPrice } from '../../utils/stringUtils';
import { deleteReview } from '../../reviews/modules/slice';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const PurchaseHistory = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { myInfo } = useSelector(state => state.myPage);

    const handleWriteReview = (id) => {
        navigate(`/review/write/${id}`);
    }

    const handleUpdateReview = (reviewId) => {
        navigate(`/review/modify/${reviewId}`);
    }

    const handleDeleteReview = (reviewId) => {
        dispatch(deleteReview({reviewId}));
    }

    const getRows = () => {
        let rows = [];
        if (!myInfo.payments) {
            return [];
        }
        for (let i = 0; i < myInfo.payments.length; i++) {
            rows = rows.concat(myInfo.payments[i].payment_items.map((t, idx) => {
                let item = {
                    ...t.product,
                    item_capacity: t.item_capacity,
                    id: myInfo.payments[i].id,
                    rowId: (idx === 0 ? myInfo.payments[i].id : -idx),
                    created_at: myInfo.payments[i].created_at,
                    purchase_title: myInfo.payments[i].store.title,
                    has_review: !myInfo.payments[i].reviewable,
                    review_id: myInfo.payments[i].review_id,
                    length: myInfo.payments[i].payment_items.length,
                };

                return item;
            }));
        }

        return rows;
    }

    const rows = getRows();

    return (
        <div className={cx("my-page-purchase-history")}>
            <div className={cx("my-page-purchase-history-header")}>
                <h3 className={cx("my-page-subtitle")}>구매내역</h3>
                <Link className={cx("purchase-history-button")} to="/payment">
                    <span>전체 보기</span>
                    <RightArrow className={cx("fill-gray-800-color")} width={16} height={16} />
                </Link>
            </div>
            <table className={cx("purchase-history-table")}>
                <thead>
                    <tr>
                    <th className={cx("purchase-date-column")}>주문일<br/>(주문매장)</th>
                    <th>상품정보</th>
                    <th className={cx("purchase-price-column")}>결제 금액</th>
                    <th className={cx("purchase-review-column")}></th>
                    </tr>
                </thead>
                <tbody>
                    {!!rows ? rows.map((row, idx) => {
                        if (row.rowId >= 0) {
                            return <tr className={cx("purchase-history-row", row.length <= 1 ? "last-row": '')}>
                                <td className={cx("purchase-date-cell")} rowSpan={row.length > 1 ? row.length : 1}>
                                    <span className={cx("purchase-date-text")}>{getDateString(row.created_at)}</span><br/>
                                    <span className={cx("purchase-spot")}>{row.purchase_title}</span>
                                </td>
                                <td>
                                    <Fragment>
                                        <div className={cx("purchase-item-cell", "first-row", row.length <= 1 ? "last-row": '')}>
                                            <img onError={handleImageError} src={row.thumb_image_url ?? defaultImage} width={64} height={64}/>
                                            <div className={cx("purchase-item-header")}>
                                                <div className={cx("purchase-item-title-line")}><span className={cx("purchase-item-title")}>{row.brand_name}</span><span className={cx("purchase-item-subtitle")}>{row.title}</span></div>
                                                <div className={cx("purchase-item-desc")}>{renderPrice(row.item_capacity)}ml</div>
                                            </div>
                                        </div>
                                    </Fragment>
                                </td>
                                <td className={cx("purchase-price-cell", "first-row", row.length <= 1 ? "last-row": '')}>{renderPrice(row.price)}원</td>
                                <td rowSpan={row.length > 1 ? row.length : 1}>
                                    {row.has_review ? <Fragment>
                                        <div className={cx("button-group")}>
                                            <button className={cx("outline-gray-button", "table-button")} onClick={() => handleUpdateReview(row.review_id)}>후기 수정</button>
                                            <button className={cx("outline-gray-button", "table-button")} onClick={() => handleDeleteReview(row.review_id)}>후기 삭제</button>
                                        </div>
                                    </Fragment> : <button className={cx("outline-button", "table-button")} onClick={() => handleWriteReview(row.id)}>
                                        후기 작성
                                    </button>}
                                </td>
                            </tr>;
                        }
                        else {
                            return <tr className={cx("purchase-history-row", row.length == -(row.rowId - 1) ? "last-row" : '')}>
                                <td>
                                    <Fragment>
                                        <div className={cx("purchase-item-cell", row.length == -(row.rowId - 1) ? "last-row" : '')}>
                                            <img onError={handleImageError} src={row.thumb_image_url ?? defaultImage} width={64} height={64}/>
                                            <div className={cx("purchase-item-header")}>
                                                <div className={cx("purchase-item-title-line")}><span className={cx("purchase-item-title")}>{row.brand_name}</span><span className={cx("purchase-item-subtitle")}>{row.title}</span></div>
                                                <div className={cx("purchase-item-desc")}>{renderPrice(row.item_capacity)}ml</div>
                                            </div>
                                        </div>
                                    </Fragment>
                                </td>
                                <td className={cx("purchase-price-cell", row.length == -(row.rowId - 1) ? "last-row" : '')}>{renderPrice(row.price)}원</td>
                            </tr>;
                        }
                    }) : ''}
                </tbody>
            </table>
        </div>
    );
};

export default PurchaseHistory;