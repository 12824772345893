import React from 'react';
import Layout from '../../layout/components/Layout';
import Title from '../../layout/components/Title';
import ReviewList from '../components/ReviewList';

const ReviewPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Title title='후기'>
                <ReviewList />
            </Title>
        </Layout>
    );
};

export default ReviewPage;