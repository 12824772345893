import React from 'react';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { Link } from 'react-router-dom';
import { defaultImage, handleImageError } from '../../utils/imageUtils';

const cx = classNames.bind(styles);

const LetterListItem = (props) => {

    const { letter } = props;

    return (
        <Link to={`/letter/${letter.id}`} className={cx("letter-list-item")}>
            <div className={cx("letter-list-item-image-view")}>
                <img className={cx("letter-list-item-image")} onError={handleImageError} src={letter.thumb_image ?? defaultImage} alt={letter.title} />
            </div>
            <div className={cx("letter-list-item-title")}>{letter.title}</div>
            <div className={cx("letter-list-item-summary")}>{letter.summary}</div>
        </Link>
    );
};

export default LetterListItem;