import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useDispatch } from 'react-redux';
import { deleteReview, getReviews } from '../modules/slice';
import { HttpStatusCode } from 'axios';

const cx = classNames.bind(styles);

const ReviewDropdown = (props) => {

    const {reviewId, setCurrPage} = props;
    const dispatch = useDispatch();

    const handleDeleteReview = () => {
        dispatch(deleteReview({reviewId, callback: (res) => {
            if (res.status === HttpStatusCode.Ok) {
                setCurrPage(1);
                dispatch(getReviews({pageNum: 1}));
            }
            else {
                alert(res.data.message);
            }
        }}));
    }

    return (
        <ul className={cx("dropdown-list")}>
            <Link className={cx("dropdown-text")} to={`/review/modify/${reviewId}`}>
                <li className={cx("dropdown-menu")}>후기 수정</li>
            </Link>
            <a className={cx("dropdown-text")} href="#" onClick={() => handleDeleteReview()}>
                <li className={cx("dropdown-alert-menu")}>후기 삭제</li>
            </a>
        </ul>
    );
}

export default ReviewDropdown;