import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { initResetPassword, requestVerificationForReset, verifyForReset } from '../modules/slice';
import { useInterval } from '../../utils/datetimeUtils';
import moment from 'moment';
import { DATETIME_FORMAT } from '../../utils/constants';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';

const cx = classNames.bind(styles);

const VerifyPhoneForReset = () => {

    const { verifyState, requestTime, errorMessage } = useSelector(state => state.resetPassword);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = localStorage.getItem('accessToken');

    const [phoneNumber, setPhoneNumber] = useState('');
    const [verifyNumber, setVerifyNumber] = useState('');
    const [remainTime, setRemainTime] = useState('');


    const [errors, setErrors] = useState({
        verifyNumber: "",
    });

    useEffect(() => {
        dispatch(initResetPassword());
    }, []);

    useInterval(
        () => {
            if (!!requestTime) {
                const now = moment();
                const limitTime = moment(requestTime, DATETIME_FORMAT).add(3, 'minutes');
                const diffMinutes = moment.duration(limitTime.diff(now)).minutes();
                const diffSeconds = moment.duration(limitTime.diff(now)).seconds();
                var diffStr = '';
                if (diffMinutes > 0) {
                    diffStr = diffMinutes + ":" + diffSeconds;
                }
                setRemainTime(diffStr);
            }
        },
        1000,
    );

    useEffect(() => {
        if (!!accessToken) {
            navigate('/', {replace: true});
        }
    }, [navigate, accessToken]);

    useEffect(() => {
        const errors = {
            verifyNumber: errorMessage,
        };
        setErrors(errors);
    }, [errorMessage]);

    const handleChangePhoneNumber = e => {

        const phone = e.target.value;

        setPhoneNumber(phone
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`));
    }

    const handleChangeVerifyNumber = e => {
        setVerifyNumber(e.target.value);
    }

    const requestVerification = () => {
        dispatch(requestVerificationForReset({phoneNumber: phoneNumber.replace(/[^0-9]/g, '')}));
    }

    const validate = () => {
        const errors = {
            verifyNumber: "",
        };
    
        if (!verifyNumber) {
            errors.verifyNumber = "인증번호를 입력하세요";
        }
    
        return errors;
    }

    const handleSubmit = e => {
        e.preventDefault()

        const errors = validate();
        // 에러 값을 설정하고
        setErrors(errors);
        // 잘못된 값이면 제출 처리를 중단한다.
        if (Object.values(errors).some(v => v)) {
          return;
        }

        dispatch(verifyForReset({
            phoneNumber: phoneNumber.replace(/[^0-9]/g, ''),
            verificationCode: verifyNumber,
        }));
    }

    return (
        <div className={cx("reset-password-view")}>
            <h1 className={cx("reset-password-title")}>비밀번호 재설정</h1>
            <div className={cx("list-item-full")}>
                <form onSubmit={handleSubmit}>
                    <p className={cx("reset-password-desc")}>가입한 휴대폰 번호를 입력해주세요.</p>
                    <div className={cx("list-item-full", "request-verify-input-field")}>
                        <input className={cx("list-item-full")}
                            type="text"
                            name="phoneNumber"
                            value={phoneNumber}
                            placeholder='휴대폰 번호(-없이 숫자만 입력)'
                            onChange={handleChangePhoneNumber}
                            maxLength={13}
                        />
                        <button className={cx("outline-button", "request-verify-button")} onClick={requestVerification} type="button">인증요청</button>
                    </div>
                    {verifyState === 'verifying' ? <>
                        <div className={cx("list-item-full", "verify-code-input-field")}>
                            <input className={cx("list-item-full", "verify-code-input")}
                                type="numeric"
                                name="verifyNumber"
                                value={verifyNumber}
                                placeholder='인증번호 입력'
                                onChange={handleChangeVerifyNumber}
                            />
                            {remainTime ? <div className={cx("count-down-text")}><span>{remainTime}</span></div> : ''}
                        </div>
                        {errors.verifyNumber && <div className={cx("input-error-text", "reset-password-error-text")}><span>{errors.verifyNumber}</span></div>}
                        <div className={cx("list-item-full", "resend-verify-request")} onClick={requestVerification}>
                            <div className={cx("text-link-button")}><span className={cx("text-link-button-inner")}>인증번호 재발송</span></div>
                        </div>
                    </> : <>
                        {errors.verifyNumber && <div className={cx("input-error-text", "reset-password-error-text")}><span>{errors.verifyNumber}</span></div>}
                    </>}
                    
                    <div className={cx("list-item-full")}>
                        <button disabled={verifyState !== 'verifying'} className={cx("list-item-full", "confirm-button", "primary-button")} type="submit">인증 확인</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VerifyPhoneForReset;