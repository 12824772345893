import React, { useEffect } from 'react';
import MainBanner from './MainBanner';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import SearchBanner from './SearchBanner';
import HowToUseBanner from './HowToUseBanner';
import NoticeBanner from './NoticeBanner';
import EventBanner from './EventBanner';
import { useDispatch, useSelector } from 'react-redux';
import { getHomeFeeds } from '../modules/slice';

const cx = classNames.bind(styles);

const Home = () => {

    const dispatch = useDispatch();
    const { feeds } = useSelector(state => state.home);

    useEffect(() => {
        dispatch(getHomeFeeds());
    }, [dispatch]);

    return (
        <div className={cx("home-view")}>
            <MainBanner banners={feeds.banners} />
            <SearchBanner />
            <HowToUseBanner />
            <NoticeBanner notices={feeds.announcements} />
            <EventBanner events={feeds.events} />
        </div>
    );
};

export default Home;