import React from 'react';
import EventListItem from './EventListItem';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { Link } from 'react-router-dom';
import {ReactComponent as RightArrow} from '../../icons/arrow-right.svg';

const cx = classNames.bind(styles);

const EventBanner = (props) => {

    const { events } = props;

    return (
        <div className={cx("home-banner", "horizontal-banner")}>
            <div className={cx("event-banner-title-view")}>
                <Link className={cx("home-banner-title")} to="/event">특별한 혜택</Link>
                <div className={cx("home-banner-desc")}>그린필박스 이벤트를 만나보세요.</div>
                <Link className={cx("event-banner-button")} to="/event">
                    <span>자세히 보기</span>
                    <RightArrow className={cx("fill-green-70-color")} width={16} height={16} />
                </Link>
            </div>
            <div className={cx("event-list-view")}>
                {!!events ? events.map((event) => {
                    return <EventListItem key={event.id} event={event} />
                }) : ''}
            </div>
        </div>
    );
};

export default EventBanner;