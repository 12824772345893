import React, { useEffect, useRef, useState } from 'react';
import { upload } from '../../utils/imageUtils';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {ReactComponent as Picture} from '../../icons/picture.svg';
import Loading from '../../Loading';
import { useDispatch, useSelector } from 'react-redux';
import { getReviewDetail, updateReview } from '../modules/slice';
import { HttpStatusCode } from 'axios';

const cx = classNames.bind(styles);

const ModifyReview = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { reviewId } = useParams();
    const { detail } = useSelector(state => state.review);
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const [reviewContent, setReviewContent] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const fileElement = useRef(null);

    useEffect(() => {
        dispatch(getReviewDetail({reviewId}));
    }, [reviewId]);

    useEffect(() => {
        setReviewContent(detail.content);
        setImageUrl(detail.review_image_url);
    }, [detail]);

    const onUploaded = (url) => {
        setImageUrl(url);
    }

    const selectImage = () => {
        if (!loading) {
            fileElement.current.click();
        }
    };

    const uploadImage = (e) => {
        if (fileElement.current.files.length === 1) {
            try {
                e.persist();
                setLoading(true);
                upload(
                    fileElement.current.files[0],
                    (percent) => {
                    setUploadProgress(percent);
                    },
                    (result) => {
                    setLoading(false);
                    setUploadProgress(0);
                    onUploaded(`https://grfimgbucket.s3.ap-northeast-2.amazonaws.com/${result.Key}`);
                    },
                );
            } catch (err) {
                if (err) {
                    alert(err.message);
                    throw new Error(err);
                }
            }
        } else {
            alert('파일을 선택해주세요');
        }
    };
 
    const handleChangeReviewContent = e => {
        setReviewContent(e.target.value);
    }

    const onWriteReview = () => {
        dispatch(updateReview({reviewId, reviewContent, imageUrl, callback: (res) => {
            if (res.status === HttpStatusCode.Ok) {
                navigate(-1);
            }
            else {
                alert(res.data.message);
            }
        }}));
    }

    return (
        <div className={cx("write-review-container")}>
            <div className={cx("write-review-body")}>
                <h5 className={cx("write-review-header")}>상품은 어떠셨나요?</h5>
                <textarea className={cx("write-review-text-field")}
                    placeholder='최소 15자 이상 작성해주세요.'
                    value={reviewContent}
                    onChange={handleChangeReviewContent}
                />
                <input type="file"
                    onChange={uploadImage}
                    ref={fileElement}
                    accept='image/*'
                    multiple={false}
                    style={{ display: 'none' }}
                />
                {!!imageUrl && <img src={imageUrl} alt="review-image" width={160} height={160} />}
                <button className={cx("outline-button", "picture-button")} onClick={selectImage}>
                    <Picture width={24} height={24} />
                    <span>{!!imageUrl ? '사진 변경' : '사진 추가'}</span>
                </button>
                <Loading loading={loading} />
            </div>
            <button className={cx("primary-button")} disabled={!!reviewContent ? reviewContent.length >= 15 ? false : true : true} onClick={onWriteReview}>
                후기 수정
            </button>
        </div>
    );
};

export default ModifyReview;