import React from 'react';
import SearchList from './SearchList';
import SearchMap from './SearchMap';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';

const cx = classNames.bind(styles);

const Locate = () => {
    return (
        <div className={cx("locate-view")}>
            <SearchList />
            <SearchMap />
        </div>
    );
};

export default Locate;