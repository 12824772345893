import React from 'react';
import Layout from '../../layout/components/Layout';
import PurchaseHistoryDetail from '../components/PurchaseHistoryDetail';
import Title from '../../layout/components/Title';

const PurchaseHistoryDetailPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Title title="구매 상세내역">
                <PurchaseHistoryDetail />
            </Title>
        </Layout>
    );
};

export default PurchaseHistoryDetailPage;