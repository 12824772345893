import React from 'react';
import Layout from '../../layout/components/Layout';
import Title from '../../layout/components/Title';
import WriteReview from '../components/WriteReview';

const WriteReviewPage = () => {

    return (
        <Layout hasHeader={true} hasFooter={true}>
            <Title title='후기 작성'>
                <WriteReview />
            </Title>
        </Layout>
    );
};

export default WriteReviewPage;