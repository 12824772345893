import React from 'react';
import classNames from 'classnames/bind';
import styles from '../../App.module.scss';

const cx = classNames.bind(styles);

const DetergentListFilters = (props) => {

    const { filter, onChangeFilter } = props;

    return (
        <ul className={cx("detergent-filter-list")}>
            <a href="#" onClick={() => onChangeFilter('')}>
                <li className={cx("detergent-filter-item", filter === '' ? 'selected' : '')}>
                    전체
                </li>
            </a>
            <a href="#" onClick={() => onChangeFilter('LAUNDRY_DETERGENT')}>
                <li className={cx("detergent-filter-item", filter === 'LAUNDRY_DETERGENT' ? 'selected' : '')}>
                    세탁 세제
                </li>
            </a>
            <a href="#" onClick={() => onChangeFilter('FABRIC_SOFTENER')}>
                <li className={cx("detergent-filter-item", filter === 'FABRIC_SOFTENER' ? 'selected' : '')}>
                    섬유유연제
                </li>
            </a>
            <a href="#" onClick={() => onChangeFilter('DISH_DETERGENT')}>
                <li className={cx("detergent-filter-item", filter === 'DISH_DETERGENT' ? 'selected' : '')}>
                    주방세제
                </li>
            </a>
        </ul>
    );
};

export default DetergentListFilters;