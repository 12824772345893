import React from 'react';
import Layout from '../../layout/components/Layout';
import RegisterUser from '../components/RegisterUser';
import Welcome from '../components/Welcome';
import VerifyPhoneForRegister from '../components/VerifyPhoneForRegister';
import { useSelector } from 'react-redux';

const RegisterPage = () => {

    const {verifyState} = useSelector(state => state.registerUser);

    return (
        <Layout hasHeader={true} hasFooter={true}>
            {verifyState === 'verified' ? <RegisterUser />  : verifyState === 'registered' ? <Welcome /> : <VerifyPhoneForRegister />}
        </Layout>
    );
};

export default RegisterPage;