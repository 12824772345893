import React, { useEffect, useState } from 'react';
import {ReactComponent as RightArrow} from '../../icons/arrow-right.svg';

import classNames from 'classnames/bind';
import styles from '../../App.module.scss';

const cx = classNames.bind(styles);

const PageNumbers = (props) => {

    const { currPage, maxPage, onChangePage } = props;
    const [firstPage, setFirstPage] = useState(1);
    const [lastPage, setLastPage] = useState(5);
    
    useEffect(() => {
        const fPage = Math.floor((currPage - 1) / 5) * 5;
        setFirstPage(fPage + 1);
        if (maxPage > fPage + 5) {
            setLastPage(fPage + 5);
        }
        else {
            setLastPage(maxPage);
        }
    }, [currPage, maxPage]);

    const renderPages = (first, last) => {
        let pageArray = [];
        for (let i = 0; i < last - first + 1; i++) {
            pageArray.push(first + i);
        }

        let pageElements = [];
        if (first > 1) {
            pageElements.push(<a key={first - 1} href="#" className={cx("page-number-item")} onClick={() => onChangePage(firstPage - 1)}>
                <RightArrow className={cx("left-arrow")} />
            </a>);
        }

        pageElements = pageElements.concat(pageArray.map((page) => <a key={page} href="#" className={cx("page-number-item", "page-number", currPage === page ? "selected" : "")} onClick={() => page !== currPage ? onChangePage(page) : ''}>
                {page}
            </a>)
        );

        if (last < maxPage) {
            pageElements.push(<a key={last + 1} href="#" className={cx("page-number-item")} onClick={() => onChangePage(lastPage + 1)}>
                <RightArrow className={cx("right-arrow")} />
            </a>);
        }

        return pageElements;
    }

    return (
        <div className={cx("page-number-view")}>
            {renderPages(firstPage, lastPage)}
        </div>
    );
};

export default PageNumbers;